<template>
  <div class="card">
    <div class="card-body">
    <div class="row">
      <div class="col-lg-2 col-sm-6 col-xs-12">
        <!-- Company Logo & Action Buttons -->
        <div class="d-flex flex-column justify-content-start">
          <div class="mb-1 align-self-lg-start align-self-center">
              <h4 class="mb-0">
                {{ companyInfo.name }}
              </h4>
            </div>
            <p-avatar :text="avatarText(companyInfo.name)" size="200px" color="light-primary" class="display-3" style="font-weight:bolder" />
        </div>
      </div>

      <div class="col-lg-5 col-sm-6 col-xs-12">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon icon="UserIcon" class="mr-75" />
              <span class="font-weight-bold">Account / Suite No</span>
            </th>
            <td class="pb-50">
              <span class="text-capitalize d-block badge badge-danger badge-pill">
                  {{ companyInfo.accountNo }} / {{ companyInfo.suiteNo }}
              </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              <span
                  pill
                  :class="`badge-light-${resolveStatusVariant(companyInfo.status)}`"
                  class="text-capitalize d-block badge badge-pill"
                >
                  {{ companyInfo.status }}
                </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Company Type</span>
            </th>
            <td class="pb-50">
              {{ companyInfo.companyType }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
                <feather-icon
                    icon="UserIcon"
                    class="mr-75"
                />
                <span class="font-weight-bold">Acc Manager</span>
            </th>
            <td class="pb-50">
                {{ companyInfo.accountManagerName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
                <feather-icon
                    icon="KeyIcon"
                    class="mr-75"
                />
                <span class="font-weight-bold">Username</span>
            </th>
            <td class="pb-50">
                {{ companyInfo.userLoginName }}
            </td>
          </tr>
        </table>
      </div>

      <div class="col-lg-5 col-sm-12">
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="AtSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ getPrimaryEmail(companyInfo) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td class="pb-50">
                {{ getPrimaryPhone() }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MessageSquareIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">WhatsApp</span>
            </th>
            <td class="pb-50">
              <span class="text-capitalize badge badge-pill badge-light-success">
                  {{ getWhatsApp() }}
                </span>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ getPrimaryAddress(companyInfo) }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Store Count</span>
            </th>
            <td class="pb-50">
              {{ companyInfo.stores.length }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                  icon="UserIcon"
                  class="mr-75"
              />
              <span class="font-weight-bold">Problem Manager</span>
            </th>
            <td class="pb-50">
              {{ companyInfo.problemManagerName }}
            </td>
          </tr>
          <tr>
            <th />
            <td>
            <div class="demo-inline-spacing">
              <b-button
                v-if="isNewUserRole" variant="primary"
                @click="activateAccountEmail"
              >
                Reactivate Account
              </b-button>
            </div>
            </td>
          </tr>
        </table>
      </div>
      <div class="col-lg-12">
        <!--Begin:: Create Or Update Form-->
        <dx-util-popup
          ref="reActivatePopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          :height="375"
          title="Reactivate Account Request"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="saveButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="reActivateFormRef" :form-data="activationForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="companyData">
              <dx-util-item data-field="reason" :editor-options="{ height: 80 }" editor-type="dxTextArea" :label="{text: 'Reason'}">
                <dx-util-required-rule message="Reason is required" />
              </dx-util-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'
import findIndex from 'lodash/findIndex'
import toLower from 'lodash/toLower'
import companyService from '@/http/requests/company/companyService'

export default {
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({ avatarText, userData: null, activationForm: { reason: null } }),
  computed: {
    reActivatePopup() {
      const reActivatePopup = this.$refs.reActivatePopupRef.instance
      return reActivatePopup
    },
    isNewUserRole() {
      if (this.userData && this.userData.role && this.userData.role.roleName && this.userData.role.roleName === 'CompanyNewUserRole'
        && this.userData.companyStatus && this.userData.companyStatus === 'passive') {
        return true
      }
      return false
    },
    saveButtonOptions() {
      return {
        text: 'Submit Request',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.reActivatePopup.hide()
        },
      }
    },
  },
  mounted() {
    const userData = getUserData()
    this.userData = userData
  },
  methods: {
    handleSubmit() {
      const self = this
      const form = this.$refs.reActivateFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        companyService.activateAccountRequestEmail(this.activationForm.reason).then(() => {
          self.reActivatePopup.hide()
        })
      }
    },

    resolveStatusVariant(status) {
      if (status) {
        if (toLower(status) === 'active') return 'success'
        if (toLower(status) === 'passive') return 'secondary'
        if (toLower(status) === 'pending') return 'warning'
      }

      return 'primary'
    },
    getPrimaryEmail(companyInfo) {
      if (companyInfo && companyInfo.emails && companyInfo.emails.length > 0) {
        const emails = companyInfo.emails
        const index = findIndex(emails, u => u.primary === true)
        if (index !== -1) {
          return emails[index].email
        }
        if (emails.length > 0) {
          return emails[0].email
        }
      }
      return 'N/A'
    },
    getPrimaryPhone() {
      if (this.companyInfo && this.companyInfo.phones) {
        const index = findIndex(this.companyInfo.phones, u => u.primary === true)
        if (index !== -1) {
          return this.companyInfo.phones[index].phone
        }
        if (this.companyInfo.phones.length > 0) {
          return this.companyInfo.phones[0].phone
        }
      }
      return 'N/A'
    },
    getWhatsApp() {
      if (this.companyInfo && this.companyInfo.phones) {
        const index = findIndex(this.companyInfo.phones, u => toLower(u.phoneType) === 'whatsapp')
        if (index >= 0) {
          return this.companyInfo.phones[index].phone
        }
      }
      return 'N/A'
    },
    getPrimaryAddress(companyInfo) {
      if (companyInfo && companyInfo.addresses && companyInfo.addresses.length > 0) {
        const pIndex = findIndex(companyInfo.addresses, u => u.primary === true)
        const index = pIndex !== -1 ? pIndex : 0
        const address = companyInfo.addresses[index]
        return address.line1.concat(address.line2 ? ' '.concat(address.line2) : '')
          .concat(' ').concat(address.city ? ' '.concat(address.city) : '')
          .concat(address.state ? ' '.concat(address.state) : '')
          .concat(' ')
          .concat(address.zipcode)
      }
      return 'N/A'
    },
    activateAccountEmail() {
      this.reActivatePopup.show()
    },
  },
}
</script>
