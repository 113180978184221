<template>
  <div class="container-fluid" style="min-height: 60vh;">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="paymentMethods"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
            <dx-item location="after" template="bankAccountButtonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="nickname" />
          <dx-column data-field="cardType" cell-template="cardTypeTemplate" />
          <dx-column data-field="cardExp" caption="Expiration Date" />
          <dx-column data-field="cardLastFour" caption="Last Four Digits" />
          <dx-column data-field="isDefault" caption="Is Default" cell-template="isDefaultTemplate" />
          <dx-column data-field="addedAt" caption="Added At" data-type="date" />
          <dx-column data-field="addressFull" caption="Address" cell-template="addressTemplate" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Credit Card" @click="onClickAddCreditCard($event)" />
          </template>
          <template #bankAccountButtonTemplate>
            <dx-util-button icon="add" type="default" text="Bank Account" @click="onClickAddBankAccount($event)" />
          </template>
          <template #isDefaultTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolveStatusVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #cardTypeTemplate="{data}">
            <div>
              {{ data.data.cardType ? data.data.cardType.toUpperCase() : 'ACH' }}
            </div>
          </template>
          <template #addressTemplate="{data}">
            <div class="d-flex justify-content-between">
              <span class="align-self-center">
                {{ data.value }}
              </span>
              <dx-util-button icon="bi bi-pencil-square" hint="Update Address" type="default" @click="onClickUpdateAddress(data.data)" />
            </div>
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-toggle-on"
                class="mr-half" hint="Set as default"
                @click="setAsDefaultPaymentMethod(data.data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="deleteItem(data.data)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <tokenize-credit-card v-if="reCreateTokenizeCreditCardComponent" @on-emit-close-tokenize-credit-card="onEmitCloseTokenizeCreditCard" />
    <tokenize-bank v-if="reCreateTokenizeBankComponent" @on-emit-close-tokenize-bank-account="onEmitCloseTokenizeBankAccount" />
    <update-address v-if="reCreateUpdateAddressComponent" @on-emit-close-update-address-popup="onEmitCloseUpdateAddressPopup" />
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import { emitter } from '@/libs/bus'
import { Notify } from '@robustshell/utils'
import staxService from '@/http/requests/external/stax'
import useCurrentUser from '@/libs/app/current-user'
import TokenizeCreditCard from '../credit-card/TokenizeCreditCard.vue'
import UpdateAddress from '../credit-card/UpdateAddress.vue'
import TokenizeBank from '../credit-card/TokenizeBank.vue'

export default {
  components: {
    TokenizeCreditCard,
    TokenizeBank,
    UpdateAddress,
  },
  mixins: [GridBase],
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { dueTrialPlanExists } = useCurrentUser()
    return {
      dueTrialPlanExists,
    }
  },
  data() {
    return {
      reCreateTokenizeCreditCardComponent: false,
      reCreateTokenizeBankComponent: false,
      reCreateUpdateAddressComponent: false,
      paymentMethods: [],
      duePlans: [],
      selectedRowId: null,
    }
  },
  mounted() {
    this.getCreditCardsByCompanyId()
  },
  methods: {
    async getCreditCardsByCompanyId() {
      const companyId = this.companyInfo.id
      const result = await staxService.getAllCreditCardsByCompanyId(companyId)
      this.paymentMethods = result
    },
    resolveStatusVariant(status) {
      if (status) return 'success'
      return 'secondary'
    },
    onClickAddCreditCard(e) {
      this.reCreateTokenizeCreditCardComponent = true
      this.$nextTick(() => {
        let primaryEmail = ''
        if (this.companyInfo.emails.length === 0) {
          Notify.warning('Please add an email address to your company profile before adding a credit card.')
          return
        }

        if (this.companyInfo.emails.length === 1) {
          primaryEmail = this.companyInfo.emails[0]
        }

        if (this.companyInfo.emails.length > 1) {
          primaryEmail = this.companyInfo.emails.find(c => c.primary === true)
        }

        if (!primaryEmail) {
          Notify.warning('Please add an email address to your company profile before adding a credit card.')
          return
        }

        emitter.emit('emit-add-new-credit-card', {
          name: this.companyInfo.name,
          email: primaryEmail.email,
          companyId: this.companyInfo.id,
          stores: this.companyInfo.stores,
          address: this.companyInfo.addresses[0],
        })
      })
    },
    onEmitCloseTokenizeCreditCard(payload) {
      this.$nextTick(() => {
        this.reCreateTokenizeCreditCardComponent = false
      })
      this.reloadCreditCards(payload)
    },
    onClickAddBankAccount(e) {
      this.reCreateTokenizeBankComponent = true
      this.$nextTick(() => {
        let primaryEmail = ''
        if (this.companyInfo.emails.length === 0) {
          Notify.warning('Please add an email address to your company profile before adding a bank account.')
          return
        }
        if (this.companyInfo.emails.length === 1) {
          primaryEmail = this.companyInfo.emails[0]
        }
        if (this.companyInfo.emails.length > 1) {
          primaryEmail = this.companyInfo.emails.find(c => c.primary === true)
        }
        if (!primaryEmail) {
          Notify.warning('Please add a primary email address to your company profile before adding a bank account.')
          return
        }

        emitter.emit('emit-add-new-bank-account', {
          name: this.companyInfo.name,
          email: primaryEmail.email,
          companyId: this.companyInfo.id,
          stores: this.companyInfo.stores,
          address: this.companyInfo.addresses[0],
        })
      })
    },
    onEmitCloseTokenizeBankAccount(payload) {
      this.$nextTick(() => {
        this.reCreateTokenizeBankComponent = false
      })
      this.reloadCreditCards(payload)
    },
    async reloadCreditCards(payload) {
      const data = await staxService.getAllCreditCardsByCompanyId(this.companyInfo.id)
      this.paymentMethods = []
      this.paymentMethods = data
      if (payload) {
        Notify.success('Payment method has been added successfully')
      }
    },
    setAsDefaultPaymentMethod(item) {
      if (item.isDefault) {
        Notify.warning('The selected credit card is already the default payment method!')
        return
      }
      this.$swal({
        title: 'Please confirm that you want to set this credit card as the default peyment method?',
        text: 'The selected credit card will be charged for your next bills!',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Set as Default',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          staxService.updateDefaultCreditCard(item.id).then(() => {
            this.reloadCreditCards(true)
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'The selected credit card set as the default peyment method!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    deleteItem(item) {
      if (item.isDefault === 'true') {
        Notify.error('You cannot delete the default payment method!')
        return
      }
      this.$swal({
        title: 'Please confirm that you want to delete this credit card?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          staxService.deleteCreditCard(item.id).then(() => {
            const index = this.paymentMethods.findIndex(u => u.id === item.id)
            this.paymentMethods.splice(index, 1)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Credit card record has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
    onClickUpdateAddress(item) {
      this.reCreateUpdateAddressComponent = true
      this.$nextTick(() => {
        emitter.emit('emit-update-address', item)
      })
    },
    onEmitCloseUpdateAddressPopup(payload) {
      this.$nextTick(() => {
        this.reCreateUpdateAddressComponent = false
      })
      this.reloadCreditCards(payload)
    },
  },
}
</script>

<style></style>
