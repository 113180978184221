<template>
  <dx-util-popup
    ref="popupCreditCardTokenizeRef"
    :show-close-button="true"
    :drag-enabled="true"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    :title="creditCardTokenizePopupTitle"
    width="48vw"
    height="64vh"
    content-template="popup-content"
    @shown="onShownCreditCardTokenizePopup"
  >
    <dx-util-position at="center" my="center" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
    <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
    <template #popup-content>
      <dx-util-scroll-view width="100%" height="100%">
        <form onsubmit="return false;">
          <dx-util-form
            ref="tokenizeFormRef"
            :form-data="tokenizeFormData"
            :col-count="1"
            :show-colon-after-label="true"
            label-mode="floating"
            label-location="left"
          >
            <dx-util-simple-item data-field="storeId" editor-type="dxSelectBox" :editor-options="storeOptions" :label="{ text: 'Select Store'}" />
            <dx-util-group-item :col-count="1" caption="Address" css-class="border rounded px-1 mt-1">
              <dx-util-simple-item data-field="address1" :label="{ text: 'Address Line 1'}">
                <dx-util-required-rule message="Address is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="address2" :label="{ text: 'Address Line 2'}" />
              <dx-util-group-item :col-count="4">
                <dx-util-simple-item data-field="addressCity" :label="{ text: 'City'}">
                    <dx-util-required-rule message="City is required" />
                </dx-util-simple-item>
                <dx-util-simple-item v-if="!selectState" data-field="addressState" :editor-options="{ maxLength: 2 }" :label="{ text: 'State'}" />
                <dx-util-simple-item v-if="selectState" data-field="addressState" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}" />
                <dx-util-simple-item data-field="addressZip" :label="{ text: 'Zip Code'}">
                  <dx-util-required-rule message="Zip Code is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="addressCountry" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}" />
              </dx-util-group-item>
            </dx-util-group-item>
            <dx-util-group-item :col-count="4" caption="Card Info" css-class="border rounded px-1 mt-1">
              <dx-util-simple-item data-field="firstName" :col-span="2">
                <dx-util-required-rule message="First Name is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="lastName" :col-span="2">
                <dx-util-required-rule message="Last Name is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="phone" :editor-options="phoneOptions" :col-span="2">
                <dx-util-required-rule message="Phone Number is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="email" :col-span="2" />
              <dx-util-simple-item :col-span="2">
                <template #default>
                  <div>
                    <div id="card-number" class="form-control mt-1" />
                  </div>
                </template>
              </dx-util-simple-item>
              <dx-util-simple-item :col-span="1">
                <template #default>
                  <div>
                    <div id="card-cvv" class="form-control mt-1" />
                  </div>
                </template>
              </dx-util-simple-item>
              <dx-util-simple-item data-field="expiryDate" editor-type="dxDateBox" :editor-options="cardExpiryDateOptions" :col-span="1">
                <dx-util-required-rule message="Expiry Date is required" />
              </dx-util-simple-item>
            </dx-util-group-item>
          </dx-util-form>
        </form>
      </dx-util-scroll-view>
    </template>
  </dx-util-popup>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import useCurrentUser from '@/libs/app/current-user'
import { emitter } from '@/libs/bus'
import staxService from '@/http/requests/external/stax'
import moment from 'moment'
import { Notify } from '@robustshell/utils/index'
import { countriesDetail, states } from '@/http/requests/statics/locations'

export default {
  setup() {
    const {
      isServingTenant,
      isIndividualTenant,
      isCompanyTypeWarehouse,
      userTenantId,
      userTenantType,
    } = useCurrentUser()
    return {
      isCompanyTypeWarehouse,
      isServingTenant,
      isIndividualTenant,
      userTenantId,
      userTenantType,
    }
  },
  data() {
    return {
      staxIns: null,
      creditCardTokenizePopupTitle: '',
      token: '',
      companyId: null,
      stores: [],
      responseData: null,
      tokenizeFormData: {
        firstName: '',
        lastName: '',
        phone: '',
        year: null,
        month: null,
        expiryDate: null,
        storeId: '',
        company: '',
        email: '',
        address1: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressCountry: '',
        addressZip: '',
      },
      selectState: true,
    }
  },
  computed: {
    formIns() {
      const form = this.$refs.tokenizeFormRef.instance
      return form
    },
    popupIns() {
      const popup = this.$refs.popupCreditCardTokenizeRef.instance
      return popup
    },
    cardExpiryDateOptions() {
      const minDate = moment().add(-1, 'months').format()
      const maxDate = moment().add(10, 'years').format()
      return {
        acceptCustomValue: false,
        useMaskBehavior: true,
        displayFormat: 'MM/yy',
        min: minDate,
        max: maxDate,
        calendarOptions: {
          maxZoomLevel: 'year',
          minZoomLevel: 'century',
        },
      }
    },
    mountOptions() {
      return {
        mask: '00',
      }
    },
    yearOptions() {
      return {
        mask: '0000',
      }
    },
    phoneOptions() {
      return {
        mask: '0000000000',
      }
    },
    storeOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: false,
        items: this.stores,
        acceptCustomValue: true,
        valueExpr: 'id',
        displayExpr: 'name',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: true,
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
        onValueChanged: e => {
          if (e.value === 'USA') {
            this.selectState = true
          } else {
            this.selectState = false
          }
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleTokenize(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePopupStoreAuth()
        },
      }
    },
  },
  created() {
    emitter.on('emit-add-new-credit-card', payload => {
      const {
        name, email, companyId, stores, address,
      } = payload

      this.stores.length = 0
      this.stores.push({ id: null, name: 'For All Stores' })
      stores.forEach(item => { this.stores.push({ id: item.id, name: item.name }) })

      this.companyId = companyId
      this.tokenizeFormData.email = email
      this.tokenizeFormData.company = name
      this.tokenizeFormData.address1 = address.line1
      this.tokenizeFormData.address2 = address.line2
      this.tokenizeFormData.addressCity = address.city
      this.tokenizeFormData.addressState = address.state
      this.tokenizeFormData.addressCountry = address.country
      this.tokenizeFormData.addressZip = address.zipcode
      this.createForm(companyId)
    })
  },
  destroyed() {
    emitter.off('emit-add-new-credit-card')
  },
  methods: {
    async onShownCreditCardTokenizePopup() {
      this.creditCardTokenizePopupTitle = 'Add New Credit Card'
      this.tokenizePopupTitle = 'Add New Bank Account'
      const isPshCustomer = (this.isServingTenant && this.isCompanyTypeWarehouse) || this.isIndividualTenant
      try {
        const getToken = await staxService.getTenantWebsitePaymentsToken(isPshCustomer, this.userTenantId)
        this.token = getToken
        this.$nextTick(async () => {
          const staxJs = new StaxJs(this.token, {
            number: {
              id: 'card-number',
              placeholder: '0000 0000 0000 0000',
              type: 'text',
              format: 'prettyFormat',
            },
            cvv: {
              id: 'card-cvv',
              placeholder: 'CVV',
              type: 'text',
            },
          })
          const result = await staxJs.showCardForm()
          this.staxIns = staxJs
        })
      } catch (err) {
        this.popupIns.hide()
      }
    },
    createForm() {
      this.popupIns.show()
    },
    closePopupStoreAuth() {
      this.popupIns.hide()
      this.$emit('on-emit-close-tokenize-credit-card', false)
    },
    async handleTokenize() {
      const staxJs = this.staxIns
      staxJs.on('card_form_complete', message => {
      })
      staxJs.on('card_form_incomplete', message => {
      })
      try {
        const formValidate = this.formIns.validate()
        if (this.tokenizeFormData.addressCountry !== 'USA') {
          this.tokenizeFormData.addressState = 'NA'
        } else if (!this.tokenizeFormData.addressState) {
          Notify.warning('Address State is required. Please select address state.')
          return
        }
        if (formValidate.isValid) {
          const companyName = this.setName(this.tokenizeFormData.storeId, this.tokenizeFormData.company)
          const { expiryMonth, expiryYear } = this.setExpiryDate(this.tokenizeFormData.expiryDate)
          const extraDetails = {
            firstname: this.tokenizeFormData.firstName,
            lastname: this.tokenizeFormData.lastName,
            month: expiryMonth,
            year: expiryYear,
            email: this.tokenizeFormData.email,
            company: companyName,
            phone: this.tokenizeFormData.phone,
            address_1: this.tokenizeFormData.address1,
            address_2: this.tokenizeFormData.address2,
            address_city: this.tokenizeFormData.addressCity,
            address_state: this.tokenizeFormData.addressState,
            address_zip: this.tokenizeFormData.addressZip,
            address_country: this.tokenizeFormData.addressCountry,
            method: 'card',
            validate: false,
          }

          const response = await staxJs.tokenize(extraDetails)
          const nickname = this.setName(this.tokenizeFormData.storeId, response.nickname)
          const data = {
            cardId: response.id,
            customerId: response.customer_id,
            nickname: nickname,
            companyId: this.companyId,
            storeId: this.storeId,
            cardType: response.card_type,
            method: response.method,
            cardLastFour: response.card_last_four,
            securityCode: null,
            cardExp: response.card_exp,
            cardExpDatetime: response.card_exp_datetime,
            isDefault: response.is_default,
            bankName: response.bank_name, // --
            bankType: response.bank_type, // --
            bankHolderType: response.bank_holder_type, // --
            isUsableInVt: response.is_usable_in_vt, // --
            updatedAt: response.updated_at, // --
            createdAt: response.created_at, // --
            binType: response.bin_type, //--
            hasCvv: response.has_cvv, // --
            customer: {
              id: response.customer.id,
              email: response.customer.email,
              phone: response.customer.phone,
              firstname: response.customer.firstname,
              lastname: response.customer.lastname,
              address_1: response.customer.address_1,
              address_2: response.customer.address_2,
              address_city: response.customer.address_city,
              address_country: response.customer.address_country,
              address_state: response.customer.address_state,
              address_zip: response.customer.address_zip,
              allow_invoice_credit_card_payments: response.customer.allow_invoice_credit_card_payments,
              cc_emails: response.customer.cc_emails,
              cc_sms: response.customer.cc_sms,
              company: response.customer.company,
              created_at: response.customer.created_at,
              updated_at: response.customer.updated_at,
              deleted_at: response.customer.deleted_at,
              gravatar: response.customer.gravatar,
              notes: response.customer.notes,
              reference: response.customer.reference,
              options: response.customer.options,
            },
          }
          const createResult = await staxService.createCreditCardToken(data)
          this.popupIns.hide()
          this.$emit('on-emit-close-tokenize-credit-card', true)
        }
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
        this.popupIns.hide()
        this.$emit('on-emit-close-tokenize-credit-card', false)
      }
    },

    setName(storeId, val) {
      let name = val
      if (storeId) {
        const store = this.stores.find(c => c.id === storeId)
        name = `${name}, Store: ${store.name}`
        return name
      }
      name = `${name}, Store: All`
      return name
    },
    setExpiryDate(expiryDate) {
      const expiryMonth = (moment(expiryDate).month() + 1).toString().padStart(2, '0')
      const expiryYear = moment(expiryDate).year().toString()
      return {
        expiryMonth,
        expiryYear,
      }
    },
  },
}
</script>

<style>

</style>
