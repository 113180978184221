import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getActiveUserProfile() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userProfileController.getActiveUserProfile())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getActiveCompany() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userProfileController.getActiveCompany())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async createOrUpdateUserProfile(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.userProfileController.createOrUpdateUserProfile(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  downloadExcelTemplate() {
    const promise = new Promise((resolve, reject) => {
      axios({
        url: endpoints.system.userProfileController.downloadExcelTemplate(),
        method: 'GET',
        responseType: 'blob',
      })
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
