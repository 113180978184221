<template>
  <div class="container-fluid">
    <div v-if="currentUser.userProfileStatus === 'EMAIL_VALIDATED_PROFILE_MISSING'" class="row">
      <div class="col-12 px-0">
        <company-create-profile />
      </div>
    </div>
    <div v-else-if="currentUser.userProfileStatus === 'PENDING'" class="row">
      <div class="col-12 px-0">
        <company-info-card :company-info="companyInfo" />
      </div>
    </div>
    <div v-else-if="isActiveTab && currentUser.userProfileStatus !== 'EMAIL_VALIDATED_PROFILE_MISSING'" class="row">
      <div class="col-12 px-0">
        <company-card :company-info="companyInfo" />
      </div>
      <div class="col-12 px-0">
        <div class="card card-psh">
         <div class="card-body">
          <dx-util-tab-panel :animation-enabled="true" :defer-rendering="true" :show-nav-buttons="true" :selected-index="activeTabIndex">
            <dx-util-panel-item v-if="hasPermission(userListPermission)" title="Users" icon="bi bi-person">
              <template #default>
                <users-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="isWarehouseOwner" title="Departments" icon="bi bi-people">
              <template #default>
                <departments-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
            <!-- TODO: Delete the panel item below, if it is not needed any more -->
            <dx-util-panel-item v-if="false" title="Chat" icon="bi bi-chat-dots">
              <template #default>
                <chat-management-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Phones" icon="bi bi-telephone">
              <template #default>
                <phones-tab :common-hub="commonHub" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Emails" icon="bi bi-at">
              <template #default>
                <emails-tab :common-hub="commonHub" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Adresses" icon="bi bi-signpost">
              <template #default>
                <addresses-tab :company-name="companyInfo.name" :common-hub="commonHub" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="isWarehouseOwner" title="Warehouses" icon="icon icon-psh-storehouse-2">
              <template #default>
                <warehouses-tab :company-name="companyInfo.name" :common-hub="commonHub" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item title="Stores" icon="bi bi-shop">
              <template #default>
                <stores-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="hasPermission(credentialPermission)" title="Credentials" icon="bi bi-incognito">
              <template #default>
                <credentials-tab :common-hub="commonHub" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="userCompanyId === companyInfo.id" title="Logos" icon="bi bi-image">
              <template #default>
                <logo-management />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="isOwner" title="Payment Methods" icon="bi bi-credit-card">
              <template #default>
                <creadit-card-management-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="isSubscriptionOwner" title="Membership" icon="bi bi-person-badge">
              <template #default>
                <membership-subscriptions />
              </template>
            </dx-util-panel-item>
            <dx-util-panel-item v-if="false && companyInfo.stores && companyInfo.stores.length > 0" title="MSKU Edit" icon="bi bi-columns-gap">
              <template #default>
                <customer-msku-tab :company-info="companyInfo" />
              </template>
            </dx-util-panel-item>
        </dx-util-tab-panel>
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import companyService from '@/http/requests/company/companyService'
import userService from '@/http/requests/system/userService'
import CredentailsTab from '@/views/apps/company/tabs/CredentialsTab'
import useCurrentUser from '@/libs/app/current-user'
import CompanyInfoCard from './CompanyInfoCard.vue'
import CompanyCard from './components/CompanyCard.vue'
import CompanyCreateProfile from './company-create-profile/CompanyCreateProfile.vue'
import UsersTab from './tabs/UsersTab.vue'
import PhonesTab from './tabs/PhonesTab.vue'
import EmailsTab from './tabs/EmailsTab.vue'
import AddressesTab from './tabs/AddressesTab.vue'
import StoresTab from './tabs/StoresTab.vue'
import CustomerMskuTab from './tabs/CustomerMskuTab.vue'
import CreaditCardManagementTab from './tabs/CreaditCardManagementTab.vue'
import ChatManagementTab from './tabs/ChatManagementTab.vue'

const USER_LIST_PER = 'resource_my_company_user_list_admin_action'
const CREDENTIAL_PER = 'resource_account_company_credentials_my_account'
const ADMIN_PER = 'resource_admin'

export default {
  components: {
    'company-card': CompanyCard,
    'company-info-card': CompanyInfoCard,
    'users-tab': UsersTab,
    'phones-tab': PhonesTab,
    'emails-tab': EmailsTab,
    'addresses-tab': AddressesTab,
    'stores-tab': StoresTab,
    'credentials-tab': CredentailsTab,
    'company-create-profile': CompanyCreateProfile,
    'customer-msku-tab': CustomerMskuTab,
    'warehouses-tab': () => import('./tabs/WarehousesTab.vue'),
    'logo-management': () => import('./tabs/LogoManagement.vue'),
    'departments-tab': () => import('./tabs/DepartmentsTab.vue'),
    'membership-subscriptions': () => import('./tabs/MembershipSubscriptions.vue'),
    CreaditCardManagementTab,
    ChatManagementTab,
  },
  data: () => ({
    companyId: 0,
    isActiveTab: false,
    companyInfo: {},
    users: [],
    phones: [],
    emails: [],
    addresses: [],
    stores: [],
    credentials: [],
    creditCards: [],
    commonHub: {},
    currentUser: { userProfileStatus: '' },
    requestError: true,
    userListPermission: USER_LIST_PER,
    credentialPermission: CREDENTIAL_PER,
    adminPermission: ADMIN_PER,
    avatarText,
    activeTabIndex: 0,
  }),
  setup() {
    const { userCompanyId, userRoleName } = useCurrentUser()
    return {
      userCompanyId, userRoleName,
    }
  },
  computed: {
    isWarehouseOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER'
    },
    isOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER' || this.userRoleName === 'CompanyOwnerRole' || this.userRoleName === 'IOWNER'
    },
    isSubscriptionOwner() {
      return this.userRoleName === 'WOWNER' || this.userRoleName === 'SUPERUSER' || this.userRoleName === 'IOWNER'
    },
    hasCustomerPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  created() {
    this.activeTabIndex = this.$route.query.tab
  },
  mounted() {
    if (this.$route.params.companyId && this.$route.params.companyId > 0) {
      this.companyId = this.$route.params.companyId
    } else {
      this.companyId = this.userCompanyId
    }
    if (!this.companyId || this.companyId === 'undefined' || this.companyId === '') {
      this.companyId = 0
    }
    this.getUserData()
  },
  methods: {
    hasPermission(permission) {
      return this.$can('read', permission)
    },
    getUserData() {
      userService
        .getCurrentUser()
        .then(result => {
          this.requestError = false
          if (result.data) {
            this.currentUser = { ...result.data }
          }
          return result
        })
        .then(() => {
          if (this.currentUser.userProfileStatus !== 'EMAIL_VALIDATED_PROFILE_MISSING') {
            this.getCompanyData()
          }
        })
    },
    // TODO: Daha basitleştirilmiş bir uç kullanılabilir, her tab ayrı ayrı verisini çektiğinden ötürü.
    // commonHub ve companyId, companyName bilgileri kullanılıyor.
    getCompanyData() {
      companyService.findCompanyAllInfoByCompanyId(this.companyId).then(result => {
        this.requestError = false
        if (result.data) {
          const {
            users,
            phones,
            emails,
            addresses,
            stores,
            commonHub,
            credentials,
            creditCards,
          } = result.data
          this.companyInfo = result.data
          this.users = users
          this.phones = phones
          this.emails = emails
          this.addresses = addresses
          this.stores = stores
          this.commonHub = commonHub
          this.credentials = credentials
          this.creditCards = creditCards
          this.isActiveTab = true
        }
      })
    },
  },
}
</script>
<style lang="scss">
.dx-tab-selected {
  background-color: #1d9bd9;
  .dx-tab-text {
    color: #dceaf1;
  }
  .dx-icon {
    color: #dceaf1;
  }
}

.dx-tabs {
  border-radius: 4px 4px 0 0;
}
.dx-tab {
  border-radius: 4px 4px 0 0;
}
</style>
