<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="emails"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="primary" caption="Primary" cell-template="primaryTemplate" :width="100" />
          <dx-column data-field="email" />
          <dx-column data-field="createdBy" />
          <dx-column data-field="creationTime" data-type="date" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #primaryTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolvePrimaryVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Email" @click="openForm" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="emailFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="Email Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit.prevent="handleSubmit">
            <dx-util-form ref="emailFormRef" :form-data="emailForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="emailData">
                <dx-util-item data-field="email" editor-type="dxTextBox" :label="{text: 'Email Address'}">
                  <dx-util-required-rule message="Email is required" />
                  <dx-util-email-rule message="Valid email address is required" />
                </dx-util-item>
              <dx-util-item data-field="primary" :editor-options="primaryOptions" editor-type="dxCheckBox" :label="{text: 'Is Primary Email'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import commonEntityService from '@/http/requests/common/commonEntityService'
import { Notify } from '@/@robustshell/utils'

export default {
  mixins: [GridBase],
  props: {
    commonHub: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      emails: [],
      emailForm: {
        id: '',
        commonHub: '',
        primary: false,
        email: '',
      },
    }
  },
  computed: {
    emailFormPopup() {
      return this.$refs.emailFormPopupRef.instance
    },
    emailFormSheet() {
      return this.$refs.emailFormRef.instance
    },
    primaryOptions() {
      return {
        text: 'Primary',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.emailFormPopup.hide()
          this.emailFormSheet.resetValues()
        },
      }
    },
  },
  mounted() {
    this.getEmailsByCommonHubId()
  },
  methods: {
    async getEmailsByCommonHubId() {
      const result = await commonEntityService.fetchEmailsByCommonHub(this.commonHub.id)
      this.emails = result.data.body
    },
    resolvePrimaryVariant(status) {
      if (status) return 'success'
      return 'secondary'
    },
    openForm() {
      this.emailForm.commonHub = this.commonHub
      this.emailFormPopup.show()
    },
    onClickEdit(e) {
      this.emailForm.commonHub = e.data?.commonHub
      this.emailForm.id = e.data?.id
      this.emailForm.email = e.data?.email
      this.emailForm.primary = e.data?.primary
      this.emailFormPopup.show()
    },
    onClickDelete(e) {
      this.$swal({
        title: `Are you sure you want to delete this <span style="color: orange;">${e.data.email}</span> email?`,
        text: 'Please be aware that this email will no longer be usable on PrepShipHub, so please take necessary actions before its deletion.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type DELETE to confirm',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'DELETE') {
            try {
              await commonEntityService.deleteEmailById(e.data.id)
              this.$swal({
                icon: 'success',
                title: 'Delete',
                text: `${e.data.email} is successfully deleted`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getEmailsByCommonHubId()
            } catch (err) {
              const messeage = err.message || err
              Notify.error(messeage)
            }
          } else {
            Notify.warning('The email is not deleted. Please type "DELETE" to confirm deletion')
          }
        }
      })
    },
    async handleSubmit() {
      const validationResult = this.emailFormSheet.validate()
      if (validationResult.isValid) {
        if (this.emailForm.id) {
          await commonEntityService.updateEmail(this.emailForm)
        } else {
          await commonEntityService.createEmail(this.emailForm)
        }
        this.getEmailsByCommonHubId()
        this.emailFormPopup.hide()
      }
    },
    resetForm() {
      this.emailFormSheet.resetValues()
      this.emailForm.id = ''
    },
  },
}
</script>

<style lang="scss">
</style>
