import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async createDefaultDepartments(tenantId) {
    const result = await axios.get(endpoints.system.departmentController.createDefaultDepartments(tenantId))
    return result
  },
  async getTenantDepartments() {
    const result = await axios.get(endpoints.system.departmentController.getTenantDepartments())
    return result.data.body
  },
  async createDepartment(department) {
    const result = await axios.post(endpoints.system.departmentController.createDepartment(), department)
    return result
  },
  async updateDepartment(department) {
    const result = await axios.put(endpoints.system.departmentController.updateDepartment(), department)
    return result
  },
  async deleteDepartment(departmentId) {
    const result = await axios.delete(endpoints.system.departmentController.deleteDepartment(departmentId))
    return result
  },
  async associateDepartmentWarehouseUser(departmentId, warehouseUserId, associate) {
    const result = await axios.get(endpoints.system.departmentController.associateDepartmentWarehouseUser(departmentId, warehouseUserId, associate))
    return result
  },
  async associateCustomerCompanyWarehouseUser(departmentId, customerCompanyId, warehouseUserId, associate) {
    const result = await axios.get(endpoints.system.departmentController.associateCustomerCompanyWarehouseUser(departmentId, customerCompanyId, warehouseUserId, associate))
    return result
  },
  async getDepartmentUsers(departmentId) {
    const result = await axios.get(endpoints.system.departmentController.getDepartmentUsers(departmentId))
    return result.data.body
  },
  async getDepartmentsByUserId(userId) {
    const result = await axios.get(endpoints.system.departmentController.getDepartmentsByUserId(userId))
    return result.data.body
  },
  async getDepartmentUsersAssociateCustomerCompany(departmentId, customerCompanyId) {
    const result = await axios.get(endpoints.system.departmentController.getDepartmentUsersAssociateCustomerCompany(departmentId, customerCompanyId))
    return result.data.body
  },
}
