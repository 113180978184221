<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="stores"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :default-sort-by="{ fieldName: 'commercePlatform', direction: 'asc' }"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="commercePlatform" cell-template="commercePlatformTemplate" caption="Platform" width="90" sort-order="asc" />
          <template #commercePlatformTemplate="{ data }">
            <div class="text-center">
              <p-lazy-img height="32px" width="auto" :src="require(`@/assets/images/svg/ecommerce/${data.value.toLowerCase()}.svg`) || defaultImg" />
            </div>
          </template>
          <dx-column data-field="name" caption="Name" cell-template="nameTemplate" />
          <template #nameTemplate="{data}">
            <div>
              <a :href="data.data.webLink" target="_blank">
               {{ data.value }}
              </a>
            </div>
          </template>
          <dx-column data-field="status" cell-template="statusTemplate" />
          <dx-column data-field="isManualImport" caption="Manual Orders Only" width="200" cell-template="manualOrderTemplate" />
          <template #manualOrderTemplate="{data}">
            <div>
              <span v-if="!data.value">
                -
              </span>
              <span v-else class="badge badge-light-secondary">
                MANUAL ORDER
              </span>
            </div>
          </template>
          <dx-column data-field="primaryOwner" />
          <dx-column data-field="openDate" data-type="date" />
          <dx-column data-field="authorization" cell-template="authorizationTemplate" width="140" />
          <template #authorizationTemplate="{data}">
            <div>
              <dx-util-button v-if="!data.data.askAuth" text="Unauthorize" class="d-block" :element-attr="customButtonAttributes" @click="handleUnauthorization(data.data)" />
              <dx-util-button v-if="data.data.askAuth && data.data.commercePlatform !== 'Walmart'" text="Authorize" :disabled="checkIntegratedPlatforms(data.data.commercePlatform)" class="d-block" type="success" @click="handleAuthorization(data.data.id, data.data.commercePlatform)" />
              <dx-util-button v-if="data.data.askAuth && data.data.commercePlatform === 'Walmart'" text="Authorize" class="d-block" type="success" @click="openStoreSettings(data.data, 3)" />
            </div>
          </template>
          <dx-column data-field="fbmOrderType" caption="FBM Connection" cell-template="fbmConnectionTemplate" width="140" />
          <template #fbmConnectionTemplate="{data}">
            <div>
              <dx-util-button v-if="data.value === 'NONE' && data.data.commercePlatform === 'AmazonSP'" text="Enable" class="d-block" type="success" @click="handleConnect(data.data.name, data.data.id, data.data.commercePlatform)" />
              <dx-util-button v-if="data.value !== 'NONE' && data.data.commercePlatform === 'AmazonSP'" text="Disable" class="d-block" :element-attr="customButtonAttributes" @click="handleDisconnect(data.data.name, data.data.id)" />
            </div>
          </template>
          <dx-column data-field="createdBy" />
          <dx-column data-field="notes" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="170" :fixed="true" fixed-position="left" />
          <template #statusTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolveStatusVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #buttonTemplate>
            <dx-util-button icon="add" text="Add Store" type="success" @click="openForm" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="aqua"
                icon="bi bi-sliders"
                class="mr-half"
                hint="Configure store settings"
                @click="openStoreSettings(data.data, 0)"
              />
              <dx-util-button
                type="warning"
                icon="bi bi-pencil-square"
                class="mr-half"
                hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                class="mr-half"
                @click="onClickDelete(data.data.id)"
              />
              <!-- <dx-util-button
                v-if="hasAdminPermission && data.data.commercePlatform === 'Shopify'"
                type="warning"
                icon="bi bi-shield"
                hint="Unauthorize store"
                @click="unauthorizeStore(data.data.id)"
              /> -->
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="storeFormPopupRef"
          :show-close-button="true"
          :drag-enabled="true"
          :close-on-outside-click="false"
          :show-title="true"
          width="70vw"
          height="auto"
          title="Store Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit.prevent="handleSubmit">
            <dx-util-form ref="storeFormRef" :form-data="storeForm" :show-colon-after-label="true" label-location="top" validation-group="storeData">
              <dx-util-item data-field="ecommerceSite" :col-span="2" :editor-options="ecommerceSiteOptions" editor-type="dxSelectBox" :label="{text: 'E-commerce Platform'}" />
              <dx-util-item template="storeUrlTemplate" :col-span="2" />
              <dx-util-item :visible="isNotAmazon" data-field="webLink" :col-span="8" editor-type="dxTextBox" :label="{text: 'Store URL'}">
                <dx-util-required-rule v-if="isWebUrlRequired" message="Store URL is required" />
              </dx-util-item>

              <template #storeUrlTemplate>
                <div>
                  <div v-if="getSupportedPlatformIdByName('Shopify') === storeForm.ecommerceSite" class="mt-half text-warning font-weight-bold">
                    <div class="alert alert-danger">
                      Please use the correct url for your store. Below is a sample to adjust it with your store name!
                    </div>
                    https://<span class="text-info">your-store-name</span>.myshopify.com
                  </div>
                  <div v-if="getSupportedPlatformIdByName('EBay') === storeForm.ecommerceSite" class="mt-half text-warning font-weight-bold">
                    <div class="alert alert-danger">
                      Please use the correct url for your store. Below is a sample to adjust it with your store name!
                    </div>
                    https://www.ebay.com/str/<span class="text-info">your-store-name</span>
                  </div>
                </div>
              </template>
              <dx-util-group-item :col-count="12">
                <dx-util-item :col-span="3" data-field="name" editor-type="dxTextBox" :label="{text: 'Store Name'}">
                  <dx-util-required-rule message="Name is required" />
                </dx-util-item>
                <dx-util-item :col-span="3" data-field="primaryOwner" editor-type="dxTextBox" :label="{text: 'Primary Owner'}">
                  <dx-util-required-rule message="Primary Owner is required" />
                </dx-util-item>
                <dx-util-item :visible="false" :col-span="3" data-field="openDate" editor-type="dxDateBox" :label="{text: 'Open Date'}" />
                <dx-util-item :visible="storeForm.id !== ''" :col-span="2" data-field="status" :editor-options="statusEditorOptions" editor-type="dxSelectBox" :label="{text: 'Status'}" />
                <dx-util-item :visible="isNotAmazon" :col-span="2" data-field="isManualImport" editor-type="dxSwitch" :label="{text: 'Only Manual Orders'}" />
              </dx-util-group-item>
              <dx-util-item :visible="!isIndividualTenant" data-field="fbmProductPrefix" editor-type="dxTextBox" :label="{text: 'MSKU Suffix'}" :editor-options="{width: 200}">
                <dx-util-pattern-rule
                  :pattern="suffixPattern"
                  message="Suffix should start with underscore (_)"
                />
              </dx-util-item>
              <dx-util-item :visible="!isIndividualTenant" template="productSuffixExplanation" />
              <dx-util-item data-field="notes" editor-type="dxTextBox" :label="{text: 'Notes'}" />
              <template #productSuffixExplanation>
                <div>
                  <p>
                    This field is used for filtering MFN/FBM orders based on SKU suffix. It is usefull when orders are fulfilled from multiple locations.
                    For example, orders with SKUs ending with {{ productSuffixSuggestion }} will be fulfilled by your company and you will receive only the filtered orders.
                  </p>
                  <p>
                    Suggested Suffix:
                    <span class="text-warning">
                      {{ productSuffixSuggestion }} <dx-util-button text="Use This" type="success" :element-attr="elementAttr" @click="useSuggestedSuffix" />
                    </span>
                  </p>
                  <p>
                    Sample MSKU:
                    <span class="text-warning">
                      NIKE_123456{{ productSuffixSuggestion }}
                    </span>
                  </p>

                </div>
              </template>
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
        <store-settings :component-id="storeSettingsCompId" :store="selectedStore" :store-id="selectedStore.id" :active-tab="activeTab" @emit-form-closed="clearSelectedStore" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import companyService from '@/http/requests/company/companyService'
import commonEntityService from '@/http/requests/common/commonEntityService'
import shopifyService from '@/http/requests/external/shopify'
import { Notify } from '@/@robustshell/utils'
import useCurrentUser from '@/libs/app/current-user'
import { v4 as uuidv4 } from 'uuid'

const defaultImageUrl = require('@/assets/images/undraw/no_images.svg')

export default {
  components: {
    'store-settings': () => import('../components/StoreSettings.vue'),
  },
  mixins: [GridBase],
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const {
      userTenantId, isIndividualTenant,
    } = useCurrentUser()
    return {
      userTenantId,
      isIndividualTenant,
    }
  },
  data() {
    return {
      integratedPlatforms: ['AmazonSP', 'Walmart', 'Shopify', 'EBay'],
      stores: [],
      storeForm: {
        id: '',
        commonHub: '',
        ecommerceSite: '',
        name: '',
        parentCompany: '',
        primaryOwner: '',
        openDate: '',
        status: 'active',
        fbmOrderType: 'NONE',
        fbmProductPrefix: '',
        isBeta: true,
        webLink: '',
        notes: '',
        isManualImport: false,
      },
      ecommerceSites: [],
      statusOptions: [
        { text: 'Active', value: 'active' },
        { text: 'Passive', value: 'passive' },
      ],
      fbmOrderTypeOptions: [
        { text: 'NONE', value: 'NONE' },
        { text: 'ALL', value: 'ALL' },
      ],
      // UrlPattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
      suffixPattern: /^_/,
      defaultImg: defaultImageUrl,
      customButtonAttributes: {
        id: 'custom-button',
        class: 'custom-button',
      },
      elementAttr: {
        class: 'p-0',
      },
      storeSettingsCompId: '',
      selectedStore: {},
      walmartAuthCompId: '',
      activeTab: 0,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    hasAdminPermission() {
      return this.$can('read', 'resource_admin')
    },
    storeFormPopup() {
      return this.$refs.storeFormPopupRef.instance
    },
    storeFormSheet() {
      return this.$refs.storeFormRef.instance
    },
    ecommerceSiteOptions() {
      return {
        dataSource: this.ecommerceSites,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    statusEditorOptions() {
      return {
        dataSource: this.statusOptions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.storeFormPopup.hide()
          this.storeFormSheet.resetValues()
        },
      }
    },
    productSuffixSuggestion() {
      return `_${this.userTenantId}MF`
    },
    isWebUrlRequired() {
      return this.getSupportedPlatformIdByName('Shopify') === this.storeForm.ecommerceSite || this.getSupportedPlatformIdByName('EBay') === this.storeForm.ecommerceSite
    },
    isNotAmazon() {
      return this.getSupportedPlatformIdByName('AmazonSP') !== this.storeForm.ecommerceSite
    },
  },
  mounted() {
    this.loadEcommerceSites()
    this.getStoresByParentCompanyId()
  },
  methods: {
    async getStoresByParentCompanyId() {
      const result = await commonEntityService.fetchStoresByParentCompany(this.companyInfo.id)
      this.stores = result.data
    },
    getSupportedPlatformIdByName(supportedECommercePlatform) {
      return this.ecommerceSites.filter(el => el.supportedECommercePlatform === supportedECommercePlatform)[0].value
    },
    resolveStatusVariant(status) {
      if (status.toLowerCase() === 'active') return 'success'
      if (status.toLowerCase() === 'passive') return 'secondary'
      if (status.toLowerCase() === 'deactivated') return 'warning'
      if (status.toLowerCase() === 'suspended') return 'danger'
      if (status.toLowerCase() === 'not_complete') return 'warning'
      if (status.toLowerCase() === 'deleted') return 'danger'
      return 'primary'
    },
    loadEcommerceSites() {
      companyService.fetchAllEcommerSiteActiveList().then(result => {
        const sites = result.data
        sites.forEach(element => {
          this.ecommerceSites.push({ value: element.id, text: element.name, supportedECommercePlatform: element.supportedECommercePlatform })
        })
        if (this.ecommerceSites.length > 0) {
          this.storeForm.ecommerceSite = this.ecommerceSites[0]?.value
        }
      })
    },
    openForm() {
      this.storeForm.commonHub = this.companyInfo.commonHub
      this.storeForm.parentCompany = this.companyInfo.id
      this.storeForm.isBeta = true
      this.storeForm.status = 'active'
      this.storeForm.ecommerceSite = this.ecommerceSites[0].value
      this.storeFormPopup.show()
    },
    onClickEdit(e) {
      this.storeForm.commonHub = this.companyInfo.commonHub
      this.storeForm.parentCompany = this.companyInfo.id
      this.storeForm.id = e.data?.id
      this.storeForm.name = e.data?.name
      this.storeForm.ecommerceSite = e.data?.ecommerceSite
      this.storeForm.primaryOwner = e.data?.primaryOwner
      this.storeForm.openDate = e.data?.openDate
      this.storeForm.fbmOrderType = e.data?.fbmOrderType
      this.storeForm.fbmProductPrefix = e.data?.fbmProductPrefix
      this.storeForm.isBeta = true
      this.storeForm.status = e.data?.status
      this.storeForm.isManualImport = e.data?.isManualImport
      this.storeForm.webLink = e.data?.webLink
      this.storeForm.notes = e.data?.notes
      this.checkAndUpdateWebLink()
      this.storeFormPopup.show()
    },
    onClickDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete this store: ${this.storeForm.name}?`,
        text: 'This will make you lose your all inbound and outbound batches data for this store.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type DELETE to confirm',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'DELETE') {
            try {
              await commonEntityService.deleteStoreById(id)
              this.$swal({
                icon: 'success',
                title: 'Delete',
                text: `${this.storeForm.name} is successfully deleted`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getStoresByParentCompanyId()
            } catch (err) {
              const messeage = err.message || err
              Notify.error(messeage)
            }
          } else {
            Notify.warning('The store is not deleted. Please type "DELETE" to confirm deletion')
          }
        }
      })
    },
    checkAndUpdateWebLink() {
      if (this.storeForm.webLink) {
        if (this.storeForm.webLink.startsWith('http://')) {
          this.storeForm.webLink = this.storeForm.webLink.replace(/^http:\/\//i, 'https://')
        } else if (!this.storeForm.webLink.startsWith('https://')) {
          this.storeForm.webLink = `https://${this.storeForm.webLink}`
        }
      }
    },
    async handleSubmit() {
      this.checkAndUpdateWebLink()
      const validationResult = this.storeFormSheet.validate()
      if (validationResult.isValid) {
        if (this.storeForm.id) {
          await commonEntityService.updateStore(this.storeForm)
        } else {
          const result = await commonEntityService.createStore(this.storeForm)
          const store = result.data
          if (!this.isIndividualTenant && !this.isNotAmazon) {
            await this.handleAuthorization(store.id, 'AmazonSP')
          }
        }
        await this.getStoresByParentCompanyId()
        this.storeFormPopup.hide()
      }
    },
    resetForm() {
      this.storeFormSheet.resetValues()
      this.storeForm.id = ''
      this.storeForm.status = 'active'
    },
    useSuggestedSuffix() {
      this.storeForm.fbmProductPrefix = this.productSuffixSuggestion
    },
    handleUnauthorization(store) {
      if (store.commercePlatform === 'Shopify') {
        this.handleShopifyUnauthorization(store)
      } else {
        this.showAskAuthOndashboard(store)
      }
    },
    // MFN authorization
    async showAskAuthOndashboard(store) {
      this.$swal({
        title: `Are you sure you want to show authorization for this store ${this.storeForm.name}?`,
        text: 'You need to go to dashboard and click the authorize button.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type ACCEPT to confirm',
        showCancelButton: true,
        confirmButtonText: 'Accept',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'ACCEPT') {
            try {
              await commonEntityService.setAskAuth(store.id)
              this.$swal({
                icon: 'success',
                title: 'Authorization',
                text: 'Authorization access has been given.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            } catch (err) {
              const messeage = err.message || err
              Notify.error(messeage)
            }
          } else {
            Notify.warning('Authorization access has not been given. Please type "ACCEPT" to confirm')
          }
        }
      })
    },
    async unauthorizeStore(storeId) {
      await shopifyService.unauthorizeStore(storeId)
    },
    handleAuthorization(storeId, platform) {
      this.$router.push({ name: 'dashboard-statistics', query: { storeId: storeId, platform: platform } })
    },
    handleShopifyUnauthorization(store) {
      this.$swal({
        title: `Are you sure you want to unauthorize this store: ${store.storeName}?`,
        text: 'This will cancel the store connection with the e-commerce platform.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type UNAUTHORIZE to confirm',
        showCancelButton: true,
        confirmButtonText: 'Unauthorize',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'UNAUTHORIZE') {
            try {
              await shopifyService.unauthorizeStore(store.id)
              this.$swal({
                icon: 'success',
                title: 'Unauthorization',
                text: `${store.storeName} is successfully unauthorized`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getStoresByParentCompanyId()
            } catch (err) {
              const message = err.message || err
              Notify.error(message)
            }
          } else {
            Notify.warning('The store cannot be unauthorized. Please type "UNAUTHORIZE" to confirm unauthorization')
          }
        }
      })
    },
    // Store Authorization
    async handleConnect(storeName, storeId, platform) {
      // first check whether it is rdt authorized
      if (platform === 'AmazonSP') {
        const result = await commonEntityService.checkRdtStatus(storeId)
        if (!result.body) {
          this.$swal({
            title: `Authorization needed for ${storeName}?`,
            text: 'You need to authorize this store for MF (Merchant Fulfilled) permissions, which allows PSH to pull buyer information'
            + 'for Direct-to-Customer Shipping. After the successful authorization you will able to enable FBM connection.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, authorize it',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then((async res => {
            if (res.value) {
              this.handleAuthorization(storeId, platform)
            }
          }))
          return
        }
      }
      this.$swal({
        title: `Are you sure you want to connect ${storeName} to FBM?`,
        text: 'You will able to use FBM modules for this store',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, connect the store',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.value) {
          const res = await commonEntityService.toggleFBMConnection(storeId, true)
          if (res) {
            this.$swal({
              icon: 'success',
              title: 'Connected',
              text: `Your ${storeName} store is successfully connected to PSH`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getStoresByParentCompanyId()
          }
        }
      }))
    },
    handleDisconnect(storeName, storeId) {
      this.$swal({
        title: `Are you sure you want to disconnect this store: ${storeName}?`,
        text: 'This will cancel the FBM features of this store',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type DISCONNECT to confirm',
        showCancelButton: true,
        confirmButtonText: 'Disconnect',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then((async result => {
        if (result.isConfirmed) {
          if (result.value === 'DISCONNECT') {
            try {
              const res = await commonEntityService.toggleFBMConnection(storeId, false)
              this.$swal({
                icon: 'success',
                title: 'Disconnected',
                text: `${storeName} is successfully disconnected`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getStoresByParentCompanyId()
            } catch (err) {
              const message = err.message || err
              Notify.error(message)
            }
          } else {
            Notify.warning('The store cannot be unauthorized. Please type "DISCONNECT" to confirm disconnection')
          }
        }
      }))
    },
    checkIntegratedPlatforms(platform) {
      return !this.integratedPlatforms.includes(platform)
    },
    openStoreSettings(data, activeTab) {
      this.selectedStore = data
      this.storeSettingsCompId = uuidv4()
      this.activeTab = activeTab
    },
    clearSelectedStore() {
      this.selectedStore = null
    },
  },
}
</script>

<style lang="scss">
#custom-button.custom-button {
  background-color: rgb(221, 72, 134, 0.1);
  color: rgb(171, 64, 100);
  border-color: rgb(171, 64, 100);
}
</style>
