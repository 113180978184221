import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getMerchantInfo(companyId) {
    const result = await axios.get(endpoints.stax.controller.getMerchantInfo(companyId))
    return result.data.body
  },
  async getMerchantRegistrationData(companyId) {
    const result = await axios.get(endpoints.stax.controller.getMerchantRegistrationData(companyId))
    return result.data.body ? JSON.parse(result.data.body) : null
  },
  async enrollMerchant(request) {
    const result = await axios.post(endpoints.stax.controller.enrollMerchant(), request)
    return result.data.body
  },
  async userEphemeralToken(userId) {
    const result = await axios.get(endpoints.stax.controller.userEphemeralToken(userId))
    return result.data.body
  },
  async getTenantWebsitePaymentsToken(isPshCustomer, tenantId) {
    let id = 0
    if (!isPshCustomer) {
      const tenantResult = await axios.get(endpoints.system.tenantController.findByTenantId(tenantId))
      const { companyId } = tenantResult.data.body
      id = companyId
    }

    const result = await axios.get(endpoints.stax.controller.getTenantWebsitePaymentsToken(id))
    return result.data.body
  },
  async getAllCreditCardsByCompanyId(companyId) {
    const result = await axios.get(endpoints.stax.controller.getAllCreditCardsByCompanyId(companyId))
    return result.data.body
  },
  async getTransactions(data) {
    const result = await axios.post(endpoints.stax.controller.getTransactions(), data)
    return result.data.body
  },
  async getTransactionsByInvoiceId(invoiceId, pagable) {
    const result = await axios.get(endpoints.stax.controller.getTransactionsByInvoiceId(invoiceId, pagable))
    return result.data.body
  },
  async getDeposits() {
    const result = await axios.get(endpoints.stax.controller.getDeposits())
    return result.data.body
  },
  async getDisputes() {
    const result = await axios.get(endpoints.stax.controller.getDisputes())
    return result.data.body
  },
  async createCreditCardToken(data) {
    const result = await axios.post(endpoints.stax.controller.createCreditCardToken(), data)
    return result.data.body
  },
  async updateCreditCardAddress(id, data) {
    const result = await axios.put(endpoints.stax.controller.updateCreditCardAddress(id), data)
    return result.data.body
  },
  async updateDefaultCreditCard(id) {
    const result = await axios.put(endpoints.stax.controller.updateDefaultCreditCard(id))
    return result.data.body
  },
  async deleteCreditCard(id) {
    const result = await axios.delete(endpoints.stax.controller.deleteCreditCard(id))
    return result.data.body
  },
  async getTeamMembers() {
    const result = await axios.get(endpoints.stax.controller.getTeamMembers())
    return result.data.body
  },
  async createTeamMember(data) {
    const result = await axios.post(endpoints.stax.controller.createTeamMember(), data)
    return result.data.body
  },
}
