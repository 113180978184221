import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getRoleResourceActionById(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.roleResourceActionController.getRoleResourceActionById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async fetchAllManagers(warehouseId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.userController.getAllManagers(warehouseId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async fetchAllAccountManagers() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.userController.getAllAccountManagers())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async fetchAllProblemManagers() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.userController.getAllProblemManagers())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getRoleResourceActionByRoleId(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.roleResourceActionController.getRoleResourceActionByRoleId(roleId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getAvailableResources(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.roleResourceActionController.getAvailableResources(roleId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async createRoleResourceAction(data) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.system.roleResourceActionController.createRoleResourceAction(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  deleteRoleResourceAction(id) {
    return new Promise((resolve, reject) => {
      axios.delete(endpoints.system.roleResourceActionController.deleteRoleResourceAction(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  updateRoleResourceAction(data) {
    return new Promise((resolve, reject) => {
      axios.put(endpoints.system.roleResourceActionController.updateRoleResourceAction(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  // get all users
  fetchUsers(ctx, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.getAllUsers(), { params: queryParams })
        .then(response => {
          const users = []
          response.data.body.forEach(user => {
            users.push({
              avatar: user.imageUrl,
              company: user.company.name,
              contact: '',
              country: '',
              currentPlan: '',
              email: user.email,
              fullName: `${user.firstName} ${user.lastName}`,
              id: user.id,
              role: user.userRole,
              status: (user.activated) ? 'active' : 'inactive',
              username: user.login,
            })
          })
          resolve({
            data: { users: users },
          })
        })
        .catch(error => reject(error))
    })
  },

  async getUserInfoBasicByQuery(filters, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.userController.getUserInfoBasicByQuery(pageable), filters)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async refreshAuthData() {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.system.userController.refreshAuthData())
        .then(response => {
          const userData = response.data.body
          localStorage.setItem('userData', JSON.stringify(userData))
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // get all users
  fetchUser(login) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.getUser(login))
        .then(response => {
          const user = response.data
          const data = {
            id: user.id,
            fullName: `${user.firstName} ${user.lastName}`,
            company: user.company.name,
            role: user.userRole.role,
            username: user.login,
            country: '',
            contact: '',
            email: user.email,
            currentPlan: '',
            status: (user.activated) ? 'active' : 'inactive',
            avatar: user.imageUrl,
          }
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },

  // get Specific Company User
  fetchCompanyUser(companyId, userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.getCompanyUser(companyId, userId))
        .then(response => {
          const user = response.data
          const data = {
            id: user.id,
            warehouseId: user.warehouseId,
            roleId: user.roleId,
            username: user.login,
            country: '',
            contact: '',
            email: user.email,
            currentPlan: '',
            firstName: user.firstName,
            middleName: user.middleName,
            lastName: user.lastName,
            status: (user.activated) ? 'active' : 'inactive',
            avatar: user.imageUrl,
          }
          resolve(data)
        })
        .catch(error => reject(error))
    })
  },
}
