<template>
  <div>
      <div class="card card-psh border">
        <div class="psh-header bg-light-primary  mb-0 d-flex align-items-center p-1">
          <div class="mr-1 border-0 px-0 py-0">
            <p-icon :name="resolveCompanyType(companyInfo.companyType)" class="mr-1" size="48px" />
          </div>
          <div class="d-flex flex-column">
            <h2 class="mb-25">
              {{ companyInfo.name }}
            </h2>
            <span v-if="isServingTenant" class="card-text mb-0 text-capitalize" style="font-weight:bold">
              {{ companyInfo.companyType }}
            <span
                :class="`badge-light-${resolveStatusVariant(companyInfo.status)}`"
                class="text-capitalize badge badge-pill px-2 mx-2 text-capitalize"
              >
                {{ companyInfo.status }}
              </span></span>
          </div>
          <div class="flex-grow-1 ml-4">
            <div v-if="isNewUserRole" class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <dx-util-button type="warning" class="text-black" text="Reactivate Account" :on-click="activateAccountEmail" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="isServingTenant" class="d-none d-sm-block">
            <span class="pb-half">Account / Suite No</span>
            <div class="col-12">
              <span class="badge badge-pill badge-danger px-2">
                {{ companyInfo.accountNo }} / {{ companyInfo.suiteNo }}
              </span>
            </div>
          </div>
        </div>
        <div class="card-body py-1">
          <div class="row">
            <div class="d-none d-lg-block mx-2">
              <p-avatar :text="avatarText(companyInfo.name)" :src="companyInfo.logo" size="120px" color="light-primary" style="font-weight:bolder" />
            </div>
            <div class="col-xl-4 col-lg-5 border-left">
              <div class="d-flex my-half d-block d-sm-none">
              <div class="col-6">
                <div class="row">
                  <p-icon name="psh-warehouse2" class="mr-1" />
                  <span>Account / Suite No</span>
                </div>
              </div>
              <div class="col-6">
                <span class="badge badge-pill badge-danger px-2 d-block">
                  {{ companyInfo.accountNo }} / {{ companyInfo.suiteNo }}
                </span>
              </div>
            </div>
            <div class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-key" class="mr-1" />
                  <span>Username</span>
                </div>
              </div>
              <div class="col-6">
                <span>
                  {{ companyInfo.userLoginName }}
                </span>
              </div>
            </div>
            <div class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-at-sign" class="mr-1" />
                  <span>Email</span>
                </div>
              </div>
              <div class="col-6 mb-half">
                <span>
                  {{ getPrimaryEmail(companyInfo) }}
                </span>
              </div>
            </div>
            <div v-if="isServingTenant" class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-user" class="mr-1" />
                  <span>Account Manager</span>
                </div>
              </div>
              <div class="col-6 mb-half">
                <span>
                  {{ companyInfo.accountManagerName }}
                </span>
              </div>
            </div>
            <div v-if="isServingTenant" class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-alert-circle" class="mr-1" />
                  <span>Problem Manager</span>
                </div>
              </div>
              <div class="col-6 mb-half">
                <span>
                  {{ companyInfo.problemManagerName }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-4 border-left ">
            <div class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-mail" class="mr-1" />
                  <span>Address</span>
                </div>
              </div>
              <div class="col-6">
                {{ getPrimaryAddress(companyInfo) }}
              </div>
            </div>
            <div class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="feather-phone" class="mr-1" />
                  <span>Phone</span>
                </div>
              </div>
              <div class="col-6">
                <span class>
                  {{ getPrimaryPhone() }}
                </span>
              </div>
            </div>
            <div v-if="isServingTenant" class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="bi-whatsapp" class="mr-1" />
                  <span>WhatsApp</span>
                </div>
              </div>
              <div class="col-6 mb-half">
                <span class="text-capitalize badge badge-pill badge-light-success">
                {{ getWhatsApp() }}
              </span>
              </div>
            </div>
            <div class="d-flex my-half">
              <div class="col-6">
                <div class="row">
                  <p-icon name="bi-shop" class="mr-1" />
                  <span>Store Count</span>
                </div>
              </div>
              <div class="col-6 mb-half">
                <span class="badge badge-warning badge-pill text-black">
                  {{ companyInfo.stores.length }}
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div>
        <!--Begin:: Create Or Update Form-->
        <dx-util-popup
          ref="reActivatePopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="Reactivate Account Request"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="reActivateFormRef" :form-data="activationForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="reactivationData">
              <dx-util-item data-field="reason" :editor-options="{ height: 80 }" editor-type="dxTextArea" :label="{text: 'Reason'}">
                <dx-util-required-rule message="Reason is required" />
              </dx-util-item>
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
import findIndex from 'lodash/findIndex'
import { getUserData } from '@/auth/utils'
import useCurrentUser from '@/libs/app/current-user'
import companyService from '@/http/requests/company/companyService'

export default {
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      avatarText,
      userData: null,
      activationForm: {
        reason: null,
      },
    }
  },
  setup() {
    const {
      isServingTenant,
      isIndividualTenant,
    } = useCurrentUser()
    return {
      isServingTenant,
      isIndividualTenant,
    }
  },
  computed: {
    reActivatePopup() {
      const reActivatePopup = this.$refs.reActivatePopupRef.instance
      return reActivatePopup
    },
    isNewUserRole() {
      if (this.userData && this.userData.role && this.userData.role.roleName && this.userData.role.roleName === 'CompanyNewUserRole'
        && this.userData.companyStatus && this.userData.companyStatus === 'passive') {
        return true
      }
      return false
    },
    saveButtonOptions() {
      return {
        text: 'Submit Request',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.reActivatePopup.hide()
        },
      }
    },
  },
  mounted() {
    const userData = getUserData()
    this.userData = userData
  },
  methods: {
    handleSubmit() {
      const form = this.$refs.reActivateFormRef.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        companyService.activateAccountRequestEmail(this.activationForm.reason).then(() => {
          this.reActivatePopup.hide()
        })
      }
    },
    resolveStatusVariant(status) {
      if (status) {
        if (status.toLowerCase() === 'active') return 'success'
        if (status.toLowerCase() === 'passive') return 'secondary'
        if (status.toLowerCase() === 'pending') return 'warning'
      }

      return 'primary'
    },
    resolveCompanyType(type) {
      if (this.isIndividualTenant) {
        return 'bi-building'
      }
      if (type) {
        if (type.toLowerCase() === 'warehouse') return 'psh-warehouse2'
        if (type.toLowerCase() === 'customer') return 'bi-building'
        if (type.toLowerCase() === 'vendor') return 'psh-manufacture'
      }
      return 'feather-home'
    },
    getPrimaryEmail(companyInfo) {
      if (companyInfo && companyInfo.emails && companyInfo.emails.length > 0) {
        const emails = companyInfo.emails
        const index = findIndex(emails, u => u.primary === true)
        if (index !== -1) {
          return emails[index].email
        }
        if (emails.length > 0) {
          return emails[0].email
        }
      }
      return 'N/A'
    },
    getWhatsApp() {
      if (this.companyInfo && this.companyInfo.phones) {
        const index = findIndex(this.companyInfo.phones, u => u.phoneType.toLowerCase() === 'whatsapp')
        if (index >= 0) {
          return this.phoneNumberFormat(this.companyInfo.phones[index].phone)
        }
      }
      return 'N/A'
    },
    getPrimaryAddress(companyInfo) {
      if (companyInfo && companyInfo.addresses && companyInfo.addresses.length > 0) {
        const pIndex = findIndex(companyInfo.addresses, u => u.primary === true)
        const index = pIndex !== -1 ? pIndex : 0
        const address = companyInfo.addresses[index]
        return address.line1.concat(address.line2 ? ' '.concat(address.line2) : '')
          .concat(' ').concat(address.city ? ' '.concat(address.city) : '')
          .concat(address.state ? ' '.concat(address.state) : '')
          .concat(' ')
          .concat(address.zipcode)
      }
      return 'N/A'
    },
    getPrimaryPhone() {
      if (this.companyInfo && this.companyInfo.phones) {
        const index = findIndex(this.companyInfo.phones, u => u.primary === true)
        if (index !== -1) {
          return this.companyInfo.phones[index].phone
        }
        if (this.companyInfo.phones.length > 0) {
          return this.companyInfo.phones[0].phone
        }
      }
      return 'N/A'
    },
    phoneNumberFormat(number) {
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`
    },
    activateAccountEmail() {
      this.reActivatePopup.show()
    },
    getSubscribedPlans() {
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
