<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="credentials"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="name" />
          <dx-column data-field="webLink" cell-template="webLinkTemplate" :width="140" />
          <dx-column data-field="status" cell-template="statusTemplate" :width="100" />
          <dx-column data-field="username" />
          <dx-column data-field="password" cell-template="passwordTemplate" />
          <dx-column data-field="createdBy" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Credentials" @click="openForm" />
          </template>
          <template #statusTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolveStatusVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #passwordTemplate="{data}">
            <credentials-hider :password="data.value" />
          </template>
          <template #webLinkTemplate="{data}">
            <a v-if="data.value" class="badge text-capitalize badge-primary d-flex justify-content-center" :href="data.value" target="_blank">
              Link
            </a>
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data.data.id)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="credentialFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="Credential Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit.prevent="handleSubmit">
            <dx-util-form ref="credentialFormRef" :form-data="credentialForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="credentialData">
              <dx-util-group-item :col-count="3">
                <dx-util-item :col-span="2" data-field="name" editor-type="dxTextBox" :label="{text: 'Name'}">
                  <dx-util-required-rule message="Name is required" />
                </dx-util-item>
                <dx-util-item data-field="status" :editor-options="statusEditorOptions" editor-type="dxSelectBox" :label="{text: 'Status'}" />
              </dx-util-group-item>
              <dx-util-item data-field="webLink" editor-type="dxTextBox" :label="{text: 'Web Link'}">
                <dx-util-required-rule message="Web Link is required" />
                <dx-util-pattern-rule message="Valid URL is required" :pattern="UrlPattern" />
              </dx-util-item>
              <dx-util-group-item :col-count="2">
                <dx-util-item data-field="username" editor-type="dxTextBox" :label="{text: 'Username'}" />
                <dx-util-item data-field="password" editor-type="dxTextBox" :label="{text: 'Password'}" />
              </dx-util-group-item>
              <dx-util-item data-field="notes" editor-type="dxTextBox" :label="{text: 'Notes'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { confirm } from 'devextreme/ui/dialog'
import commonEntityService from '@/http/requests/common/commonEntityService'

export default {
  components: {
    'credentials-hider': () => import('../credentials/CredentialsHider.vue'),
  },
  mixins: [GridBase],
  props: {
    commonHub: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      credentials: [],
      credentialForm: {
        id: '',
        commonHub: '',
        name: '',
        username: '',
        password: '',
        status: 0,
        webLink: '',
        notes: '',
      },
      statusOptions: [
        { text: 'Active', value: 0 },
        { text: 'Passive', value: 1 },
      ],
      UrlPattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,
    }
  },
  computed: {
    credentialFormPopup() {
      return this.$refs.credentialFormPopupRef.instance
    },
    credentialFormSheet() {
      return this.$refs.credentialFormRef.instance
    },
    statusEditorOptions() {
      return {
        dataSource: this.statusOptions,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.credentialFormPopup.hide()
          this.credentialFormSheet.resetValues()
        },
      }
    },
  },
  mounted() {
    this.getCredentialsByCommonHubId()
  },
  methods: {
    async getCredentialsByCommonHubId() {
      const result = await commonEntityService.fetchCredentialsByCommonHub(this.commonHub.id)
      this.credentials = result.data.body
    },
    resolveStatusVariant(status) {
      if (status.toLowerCase() === 'active') return 'success'
      if (status.toLowerCase() === 'passive') return 'secondary'
      return 'primary'
    },
    openForm() {
      this.credentialForm.commonHub = this.commonHub
      this.credentialForm.status = 0
      this.credentialFormPopup.show()
    },
    onClickEdit(e) {
      this.credentialForm.commonHub = this.commonHub
      this.credentialForm.id = e.data?.id
      this.credentialForm.name = e.data?.name
      this.credentialForm.username = e.data?.username
      this.credentialForm.password = e.data?.password
      this.credentialForm.webLink = e.data?.webLink
      this.credentialForm.notes = e.data?.notes
      const statusValue = this.getStatusValue(e.data?.status)
      this.credentialForm.status = statusValue
      this.credentialFormPopup.show()
    },
    getStatusValue(type) {
      return this.statusOptions.filter(el => el.text.toLowerCase() === type.toLowerCase())[0].value
    },
    onClickDelete(id) {
      const confirmResult = confirm(
        "Are you sure you want to delete this credential record? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await commonEntityService.deleteCredentialsById(id)
          if (result) {
            this.getCredentialsByCommonHubId()
          }
        }
      })
    },
    async handleSubmit() {
      const validationResult = this.credentialFormSheet.validate()
      if (validationResult.isValid) {
        if (this.credentialForm.id) {
          await commonEntityService.updateCredentials(this.credentialForm)
        } else {
          await commonEntityService.createCredentials(this.credentialForm)
        }
        this.getCredentialsByCommonHubId()
        this.credentialFormPopup.hide()
      }
    },
    resetForm() {
      this.credentialFormSheet.resetValues()
      this.credentialForm.id = ''
      this.credentialForm.status = 0
    },
  },
}
</script>

<style lang="scss">
</style>
