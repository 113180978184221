import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchQuery(name) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.fetchQuery(name))
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // create new user
  async createNewUser(userDTO) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.userController.createUser(), userDTO)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async updateUser(userDTO) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.userController.updateUser(), userDTO)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async getCurrentUser() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.getCurrentUser())
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async updateUserLoginInfo() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.getCurrentUser())
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async deleteUserByUsername(login) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.system.userController.deleteUserByUsername(login))
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async unSubscribeSettingsNotification(settingKey, referenceId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.unSubscribeSettingsNotification(settingKey, referenceId))
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async currentUserSubscribedMainTopics() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.userController.currentUserSubscribedMainTopics())
        .then(response => {
          resolve({ data: response.data.body })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  async subscribeMainTopic(notificationDTO) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.userController.subscribeMainTopic(), notificationDTO)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
