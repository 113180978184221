import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {

  fetchAllRoles() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getRoles())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  fetchRolesByCompanyId(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getRolesByCompanyId(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  getGrantableRolesByNewUser(newUserId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getGrantableRolesByNewUser(newUserId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  getUserSubRoles(companyId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getUserSubRoles(companyId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  getCompanyRolesExceptCompanyOwnerRole(accountNo) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getCompanyRolesExceptCompanyOwnerRole(accountNo))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
  // get all roles
  fetchGrantableRoles() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getCurrentUserGrantableRoles())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // create prep material purchase
  async createRole(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.system.roleController.createRole(), data)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async getRole(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.system.roleController.getRole(roleId))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // update prep material purchase
  async updateRole(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.system.roleController.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  // delete prep material purchase
  async deleteRole(roleId) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.system.roleController.delete(roleId))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },
}
