<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="chatUsers"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="fullName" />
          <dx-column data-field="warehouseName" />
          <dx-column data-field="chatUserType" cell-template="chatUserTypeTemplate" />
          <dx-column data-field="creationTime" caption="Created At" data-type="date" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Chat User" @click="openChatUserAssignmentPopup" />
          </template>
          <template #chatUserTypeTemplate="{data}">
            <div class="d-flex">
              <p-icon name="feather-user" :color="resolveIconColor(data.value)" class="mr-half" />
              {{ data.value }}
            </div>
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="editChatUser(data.data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data.data.id)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
    <chat-user-assignment-form
      v-if="openChatUserAssignmentForm"
      :tenant-id="currentUser.tenantId"
      :edit-user="selectedUser"
      @on-emit-close-assignment-form="onEmitCloseAssignmentForm"
    />
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { confirm } from 'devextreme/ui/dialog'
import { getUserData } from '@/auth/utils'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import Pager from '@core/dev-extreme/utils/pager'
import chatService from '@/http/requests/chat/chatService'
import ChatUserTypes from '@/enums/chatUserTypes.enum.js'

export default {
  components: {
    'chat-user-assignment-form': () => import('../chat/ChatUserAssignmentForm.vue'),
  },
  mixins: [GridBase],
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    const userData = getUserData()
    return {
      openChatUserAssignmentForm: false,
      currentUser: userData,
      selectedUser: {},
      chatUsers: [],
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
  },
  mounted() {
    this.getChatUserList()
  },
  methods: {
    getChatUserList() {
      UserSearchFilter.setDefaultFilters()
      // UserSearchFilter.q = this.query
      UserSearchFilter.accountNo = this.companyInfo.accountNo
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      chatService
        .getChatUsers(filters, pager.staticPageable)
        .then(response => {
          const data = response.content
          this.chatUsers = data
        })
    },
    editChatUser(data) {
      this.selectedUser = data
      this.openChatUserAssignmentForm = true
    },
    onClickDelete(userId) {
      const confirmResult = confirm(
        "Are you sure you want to delete this chat user? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await chatService.deleteChatUser(userId)
          if (result) {
            this.getChatUserList()
          }
        }
      })
    },
    openChatUserAssignmentPopup() {
      this.selectedUser = {}
      this.openChatUserAssignmentForm = true
    },
    resolveIconColor(roleType) {
      if (roleType === ChatUserTypes.SUPERVISOR.value) return 'danger'
      if (roleType === ChatUserTypes.ACCOUNT_MANAGER.value) return 'success'
      return 'info'
    },
    onEmitCloseAssignmentForm() {
      this.openChatUserAssignmentForm = false
      this.getChatUserList()
    },
  },
}
</script>

<style lang="scss">
</style>
