<template>
    <dx-util-popup
      ref="popupUpdateAddressRef"
      :show-close-button="true"
      :drag-enabled="true"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      :title="updateAddressPopupTitle"
      width="48vw"
      height="40vh"
      content-template="popup-content"
      @shown="onShownUpdateAddressPopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
      <template #popup-content>
        <dx-util-scroll-view width="100%" height="100%">
          <form onsubmit="return false;">
            <dx-util-form
              ref="updateAddressFormRef"
              :form-data="updateAddressFormData"
              :col-count="1"
              :show-colon-after-label="true"
              label-mode="floating"
              label-location="left"
            >
              <dx-util-simple-item data-field="address1" :label="{ text: 'Address Line 1'}">
                <dx-util-required-rule message="Address is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="address2" :label="{ text: 'Address Line 2'}" />
              <dx-util-group-item :col-count="4">
                <dx-util-simple-item data-field="addressCity" :label="{ text: 'City'}">
                    <dx-util-required-rule message="City is required" />
                </dx-util-simple-item>
                <dx-util-simple-item v-if="!selectState" data-field="addressState" :editor-options="{ maxLength: 2 }" :label="{ text: 'State'}" />
                  <dx-util-simple-item v-if="selectState" data-field="addressState" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}" />
                <dx-util-simple-item data-field="addressZip" :label="{ text: 'Zip Code'}">
                  <dx-util-required-rule message="Zip Code is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="addressCountry" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}" />
              </dx-util-group-item>
            </dx-util-form>
          </form>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */

import { emitter } from '@/libs/bus'
import staxService from '@/http/requests/external/stax'
import { Notify } from '@robustshell/utils/index'
import { countriesDetail, states } from '@/http/requests/statics/locations'

export default {
  data() {
    return {
      updateAddressPopupTitle: '',
      updateAddressFormData: {
        id: null,
        address1: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressCountry: '',
        addressZip: '',
      },
      selectState: true,
    }
  },
  computed: {
    formIns() {
      const form = this.$refs.updateAddressFormRef.instance
      return form
    },
    popupIns() {
      const popup = this.$refs.popupUpdateAddressRef.instance
      return popup
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: true,
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
        onValueChanged: e => {
          if (e.value === 'USA') {
            this.selectState = true
          } else {
            this.selectState = false
          }
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleSubmit(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePopup()
        },
      }
    },
  },
  created() {
    emitter.on('emit-update-address', payload => {
      this.updateAddressFormData = { ...payload }
      if (payload.addressState === 'NA') {
        this.updateAddressFormData.addressState = ''
      }
      this.$nextTick(() => {
        this.createForm()
      })
    })
  },
  destroyed() {
    emitter.off('emit-update-address')
  },
  methods: {
    async onShownUpdateAddressPopup() {
      this.updateAddressPopupTitle = 'Edit Credit Card Address'
    },
    createForm() {
      this.popupIns.show()
    },
    closePopup() {
      this.popupIns.hide()
      this.$emit('on-emit-close-update-address-popup', false)
    },
    async handleSubmit() {
      try {
        if (this.updateAddressFormData.addressCountry !== 'USA') {
          this.updateAddressFormData.addressState = 'NA'
        } else if (!this.updateAddressFormData.addressState) {
          Notify.warning('Address State is required. Please select address state.')
          return
        }
        const data = {
          address_1: this.updateAddressFormData.address1,
          address_2: this.updateAddressFormData.address2,
          address_city: this.updateAddressFormData.addressCity,
          address_country: this.updateAddressFormData.addressCountry,
          address_state: this.updateAddressFormData.addressState,
          address_zip: this.updateAddressFormData.addressZip,
        }

        const createResult = await staxService.updateCreditCardAddress(this.updateAddressFormData.id, data)
        this.popupIns.hide()
        this.$emit('on-emit-close-update-address-popup', true)
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
        this.popupIns.hide()
        this.$emit('on-emit-close-update-address-popup', false)
      }
    },
  },
}
</script>

<style>

</style>
