import axios from '@/libs/axios'
import endpoints from '@/http/endpoints/index'

export default {
  async generateUserAuthorizationUrl(prepStoreId) {
    const result = await axios.get(endpoints.shopify.controller.generateUserAuthorizationUrl(prepStoreId))
    return result.data.body
  },

  async shopifyAuthorization(data) {
    const result = await axios.post(endpoints.shopify.controller.shopifyAuthorization(), data)
    return result
  },

  async unauthorizeStore(storeId) {
    const result = await axios.delete(endpoints.shopify.controller.unauthorizeStore(storeId))
    return result
  },

  async createShopifyCart(data) {
    const result = await axios.post(endpoints.shopify.controller.createShopifyCart(), data)
    return result
  },

}
