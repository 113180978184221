/**
 * Enum for Item Action Type Status.
 * @readonly
 * @enum {{key: string, value: string}}
 */
const formulaChangeTypeEnum = Object.freeze({
  COUNTER_TO_FORMULA: { key: 'COUNTER_TO_FORMULA', value: 'COUNTER_TO_FORMULA', text: 'Counter To Formula' },
  FORMULA_TO_COUNTER: { key: 'FORMULA_TO_COUNTER', value: 'FORMULA_TO_COUNTER', text: 'Formula To Counter' },
  NO_CHANGE: { key: 'NO_CHANGE', value: 'NO_CHANGE', text: 'No Change' },
})

export default formulaChangeTypeEnum
