<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 80vh !important"
  >
    <div class="card w-75">
      <div class="card-header align-self-center">
        <h1 class="mt-2 mb-2">
          Business Information
        </h1>
      </div>
      <div class="card-body">
        <div class="d-flex row flex-row align-items-center">
          <div class="align-self-center col-lg-4 col-md-12">
            <div class="form-avatar" :style="{ backgroundImage: `url(${require('@/assets/images/undraw/checking.svg')})`}" />
          </div>
          <div class="align-self-center col-lg-8 col-md-12">
            <dx-util-form ref="profileFormRef" :form-data="profileForm" :col-count="1" :show-colon-after-label="true" label-location="top">
              <dx-util-group-item :col-count="3">
                <dx-util-simple-item data-field="firstName" :editor-options="inputAttrs">
                  <dx-util-required-rule message="Firstname is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="middleName" :editor-options="inputAttrs" />
                <dx-util-simple-item data-field="lastName">
                  <dx-util-required-rule message="Lastname is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="6">
                <dx-util-simple-item data-field="companyName" :col-span="4" :editor-options="inputAttrs">
                  <dx-util-required-rule message="Company Name is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-simple-item data-field="address1" :editor-options="inputAttrs">
                <dx-util-required-rule message="Address Line 1 is required" />
              </dx-util-simple-item>
              <dx-util-simple-item data-field="address2" :editor-options="inputAttrs" />
              <dx-util-group-item :col-count="4">
                <dx-util-simple-item data-field="city" :editor-options="inputAttrs">
                  <dx-util-required-rule message="City is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="state" editor-type="dxSelectBox" :editor-options="stateOptions" />
                <dx-util-simple-item data-field="zipCode" :editor-options="inputAttrs">
                  <dx-util-required-rule message="Zip Code is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="country" editor-type="dxSelectBox" :editor-options="countryOptions">
                  <dx-util-required-rule message="Country is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
              <dx-util-simple-item data-field="phoneNumber" :editor-options="inputAttrs">
                <dx-util-required-rule message="Phone Number is required" />
              </dx-util-simple-item>
              <dx-util-group-item :col-count="2">
                <dx-util-button-item :button-options="saveButtonOptions" horizontal-alignment="left" />
                <dx-util-button-item :button-options="cancelButtonOptions" horizontal-alignment="right" />
              </dx-util-group-item>
            </dx-util-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
// +1 (201) 111-2222

import { ref, computed, onMounted } from '@vue/composition-api'
import userProfileService from '@/http/requests/system/userProfileService'
import userManagementService from '@/http/requests/system/userManagementService'
import {
  countries,
  states,
} from '@/http/requests/statics/locations'

export default {
  setup() {
    const profileFormRef = ref(null)
    const inputAttrs = ref({
      autoComplete: 'none',
    })

    const profileForm = ref({})
    profileForm.value = {
      id: '',
      user: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phoneNumber: '',
      companyName: '',
      country: '',
      state: '',
      address1: '',
      address2: '',
      zipCode: '',
      city: '',
      ein: '',
    }

    const defaultProfilForm = ref({})
    defaultProfilForm.value = { ...profileForm.value }

    function getProfileData() {
      userProfileService.getActiveUserProfile().then(result => {
        const profile = result.data
        profileForm.value = {
          ...profileForm.value,
          id: profile.id,
          user: profile.user,
          firstName: profile.firstName,
          middleName: profile.middleName,
          lastName: profile.lastName,
          phoneNumber: profile.phoneNumber,
          companyName: profile.companyName,
          country: profile.country,
          state: profile.state,
          address1: profile.address1,
          address2: profile.address2,
          zipCode: profile.zipCode,
          city: profile.city,
          ein: profile.ein,
        }
      })
    }

    onMounted(() => {
      getProfileData()
    })

    function handleSubmit(e) {
      e.preventDefault()
      const form = profileFormRef.value.instance
      const formValidate = form.validate()
      if (formValidate.isValid) {
        userProfileService
          .createOrUpdateUserProfile(profileForm.value)
          .then(result => {
            userManagementService.refreshAuthData().then(() => {
              window.location.reload()
              return result
            })
          })
      }
    }

    const saveButtonOptions = computed(() => ({
      text: 'Submit',
      type: 'success',
      useSubmitBehavior: true,
      onClick: e => {
        handleSubmit(e.event)
      },
    }))

    const cancelButtonOptions = computed(() => ({
      text: 'Reset',
      type: 'danger',
      onClick: () => {
        const form = profileFormRef.value.instance
        profileForm.value = {
          ...defaultProfilForm.value,
        }
        getProfileData()
        form.option('formData', profileForm)
      },
    }))
    const countryOptions = computed(() => ({
      autoComplete: 'none',
      searchEnabled: true,
      items: countries,
    }))
    const stateOptions = computed(() => ({
      autoComplete: 'none',
      searchEnabled: true,
      items: states,
      acceptCustomValue: true,
    }))

    return {
      profileFormRef,
      inputAttrs,
      saveButtonOptions,
      cancelButtonOptions,
      profileForm,
      countryOptions,
      stateOptions,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-avatar {
  height: 256px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
