<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="phones"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="phoneType" cell-template="phoneTypeTemplate" />
          <dx-column data-field="phone" :format="phoneNumberFormat" />
          <dx-column data-field="primary" caption="Primary" cell-template="primaryTemplate" />
          <dx-column data-field="createdBy" />
          <dx-column data-field="creationTime" caption="Created At" data-type="date" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :allow-sorting="false" :fixed="true" fixed-position="left" />
          <template #primaryTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolvePrimaryVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #phoneTypeTemplate="{data}">
            <div class="d-flex">
              <p-icon :name="resolvePhoneTypeVariant(data.value)" class="mr-1 text-dark" />
              {{ data.value }}
            </div>
          </template>
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Phone" @click="openPhoneForm" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data.data.id)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="phoneFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="Phone Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="phoneFormRef" :form-data="phoneForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="phoneData">
              <dx-util-item data-field="phoneType" :editor-options="phoneTypeOptions" editor-type="dxSelectBox" :label="{text: 'Phone Type'}" />
              <dx-util-item data-field="phone" editor-type="dxTextBox" :label="{text: 'Phone Number'}">
                <dx-util-required-rule message="Phone Number is required" />
              </dx-util-item>
              <dx-util-item data-field="primary" :editor-options="primaryOptions" editor-type="dxCheckBox" :label="{text: 'Is Primary Phone'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { confirm } from 'devextreme/ui/dialog'
import commonEntityService from '@/http/requests/common/commonEntityService'

export default {
  mixins: [GridBase],
  props: {
    commonHub: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      phones: [],
      phoneForm: {
        id: '',
        phoneType: 1,
        phone: '',
        primary: false,
        commonHub: {},
      },
      phoneTypes: [
        { text: 'Home', value: 0 },
        { text: 'Work', value: 1 },
        { text: 'Mobile', value: 2 },
        { text: 'WhatsApp', value: 3 },
        { text: 'Fax', value: 4 },
      ],
    }
  },
  computed: {
    phoneFormPopup() {
      return this.$refs.phoneFormPopupRef.instance
    },
    phoneFormSheet() {
      return this.$refs.phoneFormRef.instance
    },
    primaryOptions() {
      return {
        text: 'Primary',
      }
    },
    phoneTypeOptions() {
      return {
        dataSource: this.phoneTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.phoneFormPopup.hide()
          this.phoneFormSheet.resetValues()
          this.phoneForm.phoneType = 1
        },
      }
    },
  },
  mounted() {
    this.getPhonesByCommonHubId()
  },
  methods: {
    async getPhonesByCommonHubId() {
      const result = await commonEntityService.fetchPhonesByCommonHub(this.commonHub.id)
      this.phones = result.data.body
    },
    resolvePrimaryVariant(status) {
      if (status) return 'success'
      return 'secondary'
    },
    resolvePhoneTypeVariant(phoneType) {
      if (phoneType === 'HOME') return 'feather-home'
      if (phoneType === 'WORK') return 'bi-building'
      if (phoneType === 'MOBILE') return 'bi-phone'
      if (phoneType === 'WHATSAPP') return 'bi-whatsapp'
      if (phoneType === 'FAX') return 'bi-printer'
      return 'feather-circle'
    },
    openPhoneForm() {
      this.phoneForm.commonHub = this.commonHub
      this.phoneFormPopup.show()
    },
    onClickEdit(e) {
      this.phoneForm.commonHub = e.data?.commonHub
      this.phoneForm.id = e.data?.id
      this.phoneForm.phone = e.data?.phone
      const phoneTypeValue = this.getPhoneTypeValue(e.data?.phoneType)
      this.phoneForm.phoneType = phoneTypeValue
      this.phoneForm.primary = e.data?.primary
      this.phoneFormPopup.show()
    },
    getPhoneTypeValue(type) {
      return this.phoneTypes.filter(el => el.text.toLowerCase() === type.toLowerCase())[0].value
    },
    onClickDelete(id) {
      const confirmResult = confirm(
        "Are you sure you want to delete this phone record? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await commonEntityService.deletePhoneById(id)
          if (result) {
            this.getPhonesByCommonHubId()
          }
        }
      })
    },
    async handleSubmit() {
      const validationResult = this.phoneFormSheet.validate()
      if (validationResult.isValid) {
        if (this.phoneForm.id) {
          await commonEntityService.updatePhone(this.phoneForm)
        } else {
          await commonEntityService.createPhone(this.phoneForm)
        }
        this.getPhonesByCommonHubId()
        this.phoneFormPopup.hide()
      }
    },
    resetForm() {
      this.phoneFormSheet.resetValues()
      this.phoneForm.id = ''
      this.phoneForm.phoneType = 1
    },
    phoneNumberFormat(number) {
      return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6, 10)}`
    },
  },
}
</script>

<style lang="scss">
</style>
