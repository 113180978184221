import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async getChatUsers(data, pageable) {
    const result = await axios.post(endpoints.chat.controller.getChatUsers(), data, pageable)
    return result.data.body
  },
  async assignChatUser(data) {
    const result = await axios.post(endpoints.chat.controller.assignChatUser(), data)
    return result.data.body
  },
  async updateChatUser(data) {
    const result = await axios.put(endpoints.chat.controller.updateChatUser(), data)
    return result.data.body
  },
  async deleteChatUser(id) {
    const result = await axios.delete(endpoints.chat.controller.deleteChatUser(id))
    return result.data.body
  },
  async getTenantWarehouses() {
    const result = await axios.get(endpoints.chat.controller.getTenantWarehouses())
    return result.data.body
  },
  async findUsersByWarehouseId(id) {
    const result = await axios.get(endpoints.chat.controller.findUsersByWarehouseId(id))
    return result.data.body
  },
  async findContactUsersByWarehouseId(userId, accountNo) {
    const result = await axios.get(endpoints.chat.controller.findContactUsersByWarehouseId(userId, accountNo))
    return result.data.body
  },
}
