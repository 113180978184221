<template>
  <div class="container-fluid" style="min-height:60vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="addresses"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="addressType" cell-template="addressTypeTemplate" :width="120" />
          <dx-column data-field="primary" caption="Primary" cell-template="primaryTemplate" :width="100" />
          <dx-column data-field="name" />
          <dx-column data-field="line1" caption="Details" cell-template="addressLineTemplate" />
          <dx-column data-field="city" />
          <dx-column data-field="state" :width="100" />
          <dx-column data-field="zipcode" :width="100" />
          <dx-column data-field="country" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :fixed="true" fixed-position="left" />
          <template #primaryTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolvePrimaryVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #addressTypeTemplate="{data}">
            <div class="d-flex text-uppercase">
              <p-icon :name="resolveAddressTypeVariant(data.value)" class="mr-1 text-dark" />
              {{ data.value }}
            </div>
          </template>
          <template #addressLineTemplate="{data}">
            <div>
              {{ getStreetAddress(data.data) }}
            </div>
          </template>
          <template #buttonTemplate>
            <dx-util-button icon="add" type="success" text="Add Address" @click="openAddressForm" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12">
         <!--Begin:: Create Or Update Form-->
         <dx-util-popup
          ref="addressFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="800"
          height="auto"
          title="Address Form"
          :on-hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="addressFormRef" :form-data="addressForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="addressData">
              <dx-util-group-item :col-count="3">
                <dx-util-item data-field="addressType" :editor-options="addressTypeOptions" editor-type="dxSelectBox" :label="{text: 'Address Type'}" />
                <dx-util-item data-field="name" :col-span="2" editor-type="dxTextBox" :editor-options="{placeholder: 'Company or real person name'}" :label="{text: 'Name'}">
                  <dx-util-required-rule message="Name is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-item data-field="line1" editor-type="dxTextBox" :label="{text: 'Address Line 1'}">
                <dx-util-required-rule message="Address is required" />
              </dx-util-item>
              <dx-util-item data-field="line2" editor-type="dxTextBox" :label="{text: 'Address Line 2'}" />
              <dx-util-group-item :col-count="6">
                <dx-util-item data-field="city" :col-span="2" editor-type="dxTextBox" :label="{text: 'City'}">
                  <dx-util-required-rule message="City is required" />
                </dx-util-item>
                <dx-util-item data-field="zipcode" editor-type="dxTextBox" :label="{text: 'Zipcode'}">
                  <dx-util-required-rule message="Zipcode is required" />
                </dx-util-item>
                <dx-util-simple-item v-if="!isUSAAddress" data-field="state" :label="{ text: 'State'}" />
                <dx-util-simple-item v-else data-field="state" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}">
                  <dx-util-required-rule message="State is required" />
                </dx-util-simple-item>
                <dx-util-item data-field="country" :col-span="2" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}">
                  <dx-util-required-rule message="Country is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-item data-field="primary" :editor-options="primaryOptions" editor-type="dxCheckBox" :label="{text: 'Is Primary Address'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>

    </div>
  </div>

</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { countriesDetail, states } from '@/http/requests/statics/locations'
import commonEntityService from '@/http/requests/common/commonEntityService'
import useConfirmation from '@/libs/app/confirmation'
import { Notify } from '@/@robustshell/utils'

export default {
  mixins: [GridBase],
  props: {
    companyName: {
      type: String,
      required: true,
    },
    commonHub: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { pshConfirm } = useConfirmation()
    return { pshConfirm }
  },
  data() {
    return {
      addresses: [],
      addressForm: {
        id: '',
        commonHub: '',
        addressType: '',
        name: this.companyName,
        line1: '',
        line2: '',
        city: '',
        state: '',
        country: 'USA',
        zipcode: '',
        primary: false,
      },
      addressTypes: [
        { text: 'Home', value: 0 },
        { text: 'Work', value: 1 },
      ],
    }
  },
  computed: {
    isUSAAddress() {
      return this.addressForm.country === 'USA'
    },
    addressFormPopup() {
      return this.$refs.addressFormPopupRef.instance
    },
    addressFormSheet() {
      return this.$refs.addressFormRef.instance
    },
    primaryOptions() {
      return {
        text: 'Primary',
      }
    },
    addressTypeOptions() {
      return {
        dataSource: this.addressTypes,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: false,
        inputAttr: {
          autocomplete: 'chrome-off',
        },
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
        inputAttr: {
          autocomplete: 'chrome-off',
        },
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.addressFormPopup.hide()
          this.addressFormSheet.resetValues()
          this.addressForm.addressType = 1
        },
      }
    },
  },
  mounted() {
    this.getAddressessByCommonHubId()
  },
  methods: {
    async getAddressessByCommonHubId() {
      const result = await commonEntityService.fetchAddressesByCommonHub(this.commonHub.id)
      this.addresses = result.data.body
    },
    resolvePrimaryVariant(status) {
      if (status) return 'success'
      return 'secondary'
    },
    resolveAddressTypeVariant(type) {
      if (type === 'home') return 'feather-home'
      if (type === 'work') return 'bi-building'
      return 'feather-circle'
    },
    openAddressForm(e) {
      this.addressForm.commonHub = this.commonHub
      this.addressForm.country = 'USA'
      this.addressForm.addressType = 1
      this.addressFormPopup.show()
    },
    onClickEdit(e) {
      this.addressForm = {
        id: e.data?.id,
        commonHub: e.data?.commonHub,
        name: e.data?.name,
        line1: e.data?.line1,
        line2: e.data?.line2,
        city: e.data?.city,
        zipcode: e.data?.zipcode,
        state: e.data?.state,
        country: e.data?.country,
        primary: e.data?.primary,
      }
      const addressTypeValue = this.getAddressTypeValue(e.data?.addressType)
      this.addressForm.addressType = addressTypeValue
      this.addressFormPopup.show()
    },
    getAddressTypeValue(type) {
      return this.addressTypes.filter(el => el.text.toLowerCase() === type.toLowerCase())[0].value
    },
    async onClickDelete(e) {
      const id = e.data.id
      const confirm = await this.pshConfirm(
        'Delete Address Confirmation',
        `Are you sure you want to delete this <span style="color: orange;">${e.data.name}</span> address? <br/>Please be aware that this address will no longer be usable on PrepShipHub, so please take necessary actions before its deletion.`,
        'exclamation-circle-fill',
        'Delete',
        'Cancel',
        'default',
        true,
        'DELETE',
        'Type DELETE to confirm',
      )
      if (confirm) {
        try {
          await commonEntityService.deleteAddressById(id)
          this.getAddressessByCommonHubId()
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    async handleSubmit() {
      const validationResult = this.addressFormSheet.validate()
      if (validationResult.isValid) {
        if (this.addressForm.id) {
          await commonEntityService.updateAddress(this.addressForm)
        } else {
          await commonEntityService.createAddress(this.addressForm)
        }
        this.getAddressessByCommonHubId()
        this.addressFormPopup.hide()
      }
    },
    resetForm() {
      this.addressFormSheet.resetValues()
      this.addressForm.id = ''
      this.addressForm.addressType = 1
    },
    getStreetAddress(address) {
      return `${address.line1 ? `${address.line1} ` : ''}${address.line2 ? address.line2 : ''}`
    },
  },
}
</script>

<style lang="scss">
</style>
