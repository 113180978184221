<template>
    <dx-util-popup
      ref="popupTokenizeBankAccountRef"
      :show-close-button="true"
      :drag-enabled="true"
      :close-on-outside-click="false"
      :show-title="true"
      :full-screen="false"
      :title="tokenizePopupTitle"
      width="48vw"
      height="64vh"
      content-template="popup-content"
      @shown="onShownTokenizePopup"
    >
      <dx-util-position at="center" my="center" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
      <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
      <template #popup-content>
        <dx-util-scroll-view width="100%" height="100%">
          <form onsubmit="return false;">
            <dx-util-form
              ref="tokenizeFormRef"
              :form-data="tokenizeFormData"
              :col-count="1"
              :show-colon-after-label="true"
              label-mode="floating"
              label-location="left"
            >
              <dx-util-simple-item data-field="storeId" editor-type="dxSelectBox" :editor-options="storeOptions" :label="{ text: 'Select Store'}" />
              <dx-util-group-item :col-count="1" caption="Address" css-class="border rounded px-1 mt-1">
                <dx-util-simple-item data-field="address1" :label="{ text: 'Address Line 1'}">
                  <dx-util-required-rule message="Address is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="address2" :label="{ text: 'Address Line 2'}" />
                <dx-util-group-item :col-count="4">
                  <dx-util-simple-item data-field="addressCity" :label="{ text: 'City'}">
                    <dx-util-required-rule message="City is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item v-if="!selectState" data-field="addressState" :editor-options="{ maxLength: 2 }" :label="{ text: 'State'}" />
                  <dx-util-simple-item v-if="selectState" data-field="addressState" editor-type="dxSelectBox" :editor-options="stateOptions" :label="{ text: 'State'}" />
                  <dx-util-simple-item data-field="addressZip" :label="{ text: 'Zip Code'}">
                    <dx-util-required-rule message="Zip Code is required" />
                  </dx-util-simple-item>
                  <dx-util-simple-item data-field="addressCountry" editor-type="dxSelectBox" :editor-options="countryOptions" :label="{ text: 'Country'}" />
                </dx-util-group-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2" caption="Bank Info" css-class="border rounded px-1 mt-1">
                <dx-util-simple-item data-field="firstName">
                  <dx-util-required-rule message="First Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="lastName">
                  <dx-util-required-rule message="Last Name is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="phone" :editor-options="phoneOptions">
                  <dx-util-required-rule message="Phone Number is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="email" />
                <dx-util-simple-item data-field="bankName" :col-span="2" />
                <dx-util-simple-item data-field="bankType" editor-type="dxSelectBox" :editor-options="bankTypeOptions">
                  <dx-util-required-rule message="Bank Type is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="bankHolderType" editor-type="dxSelectBox" :editor-options="bankHolderTypeOptions">
                  <dx-util-required-rule message="Bank Holder Type is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="bankAccount">
                  <dx-util-required-rule message="Bank Account Number is required" />
                </dx-util-simple-item>
                <dx-util-simple-item data-field="bankRouting">
                  <dx-util-required-rule message="Bank Routing Number is required" />
                </dx-util-simple-item>
              </dx-util-group-item>
            </dx-util-form>
          </form>
        </dx-util-scroll-view>
      </template>
    </dx-util-popup>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import useCurrentUser from '@/libs/app/current-user'
import { emitter } from '@/libs/bus'
import staxService from '@/http/requests/external/stax'
import { Notify } from '@robustshell/utils/index'
import { countriesDetail, states } from '@/http/requests/statics/locations'

export default {
  setup() {
    const {
      isServingTenant,
      isIndividualTenant,
      isCompanyTypeWarehouse,
      userTenantId,
      userTenantType,
    } = useCurrentUser()
    return {
      isCompanyTypeWarehouse,
      isServingTenant,
      isIndividualTenant,
      userTenantId,
      userTenantType,
    }
  },
  data() {
    return {
      staxIns: null,
      tokenizePopupTitle: '',
      token: '',
      companyId: null,
      stores: [],
      responseData: null,
      tokenizeFormData: {
        bankName: '',
        bankType: '',
        bankHolderType: '',
        bankAccount: '',
        bankRouting: '',
        firstName: '',
        lastName: '',
        phone: '',
        year: null,
        month: null,
        storeId: '',
        company: '',
        email: '',
        address1: '',
        address2: '',
        addressCity: '',
        addressState: '',
        addressCountry: '',
        addressZip: '',
      },
      selectState: true,
    }
  },
  computed: {
    formIns() {
      const form = this.$refs.tokenizeFormRef.instance
      return form
    },
    popupIns() {
      const popup = this.$refs.popupTokenizeBankAccountRef.instance
      return popup
    },
    phoneOptions() {
      return {
        mask: '0000000000',
      }
    },
    storeOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: false,
        items: this.stores,
        acceptCustomValue: true,
        valueExpr: 'id',
        displayExpr: 'name',
      }
    },
    stateOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: states,
        acceptCustomValue: true,
      }
    },
    countryOptions() {
      return {
        autoComplete: 'none',
        searchEnabled: true,
        items: countriesDetail,
        valueExpr: 'code',
        displayExpr: 'name',
        onValueChanged: e => {
          if (e.value === 'USA') {
            this.selectState = true
          } else {
            this.selectState = false
          }
        },
      }
    },
    bankTypeOptions() {
      return {
        items: [
          { value: 'checking', text: 'Checking' },
          { value: 'savings', text: 'Savings' },
        ],
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    bankHolderTypeOptions() {
      return {
        items: [
          { value: 'personal', text: 'Personal' },
          { value: 'business', text: 'Business' },
        ],
        valueExpr: 'value',
        displayExpr: 'text',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: e => {
          this.handleTokenize(e)
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.closePopupStoreAuth()
        },
      }
    },
  },
  created() {
    emitter.on('emit-add-new-bank-account', payload => {
      const {
        name, email, companyId, stores, address,
      } = payload

      this.stores.length = 0
      this.stores.push({ id: null, name: 'For All Stores' })
      stores.forEach(item => { this.stores.push({ id: item.id, name: item.name }) })
      this.companyId = companyId
      this.tokenizeFormData.email = email
      this.tokenizeFormData.company = name
      this.tokenizeFormData.address1 = address.line1
      this.tokenizeFormData.address2 = address.line2
      this.tokenizeFormData.addressCity = address.city
      this.tokenizeFormData.addressState = address.state
      this.tokenizeFormData.addressCountry = address.country
      this.tokenizeFormData.addressZip = address.zipcode
      this.createForm(companyId)
    })
  },
  destroyed() {
    emitter.off('emit-add-new-bank-account')
  },
  methods: {
    async onShownTokenizePopup() {
      this.tokenizePopupTitle = 'Add New Bank Account'
      const isPshCustomer = (this.isServingTenant && this.isCompanyTypeWarehouse) || this.isIndividualTenant
      try {
        const getToken = await staxService.getTenantWebsitePaymentsToken(isPshCustomer, this.userTenantId)
        this.token = getToken
        this.$nextTick(async () => {
          const staxJs = new StaxJs(this.token, {})
          this.staxIns = staxJs
        })
      } catch (err) {
        this.popupIns.hide()
      }
    },
    createForm(companyId) {
      this.popupIns.show()
    },
    closePopupStoreAuth() {
      this.popupIns.hide()
      this.$emit('on-emit-close-tokenize-bank-account', false)
    },
    async handleTokenize() {
      const staxJs = this.staxIns
      try {
        const formValidate = this.formIns.validate()
        if (this.tokenizeFormData.addressCountry !== 'USA') {
          this.tokenizeFormData.addressState = 'NA'
        } else if (!this.tokenizeFormData.addressState) {
          Notify.warning('Address State is required. Please select a state.')
          return
        }
        if (formValidate.isValid) {
          const companyName = this.setName(this.tokenizeFormData.storeId, this.tokenizeFormData.company)
          const extraDetails = {
            method: 'bank',
            bank_name: this.tokenizeFormData.bankName,
            bank_account: this.tokenizeFormData.bankAccount,
            bank_routing: this.tokenizeFormData.bankRouting,
            bank_type: this.tokenizeFormData.bankType,
            bank_holder_type: this.tokenizeFormData.bankHolderType,
            firstname: this.tokenizeFormData.firstName,
            lastname: this.tokenizeFormData.lastName,
            person_name: `${this.tokenizeFormData.firstName} ${this.tokenizeFormData.lastName}`,
            phone: this.tokenizeFormData.phone,
            email: this.tokenizeFormData.email,
            company: companyName,
            address_1: this.tokenizeFormData.address1,
            address_2: this.tokenizeFormData.address2,
            address_city: this.tokenizeFormData.addressCity,
            address_state: this.tokenizeFormData.addressState,
            address_zip: this.tokenizeFormData.addressZip,
            address_country: this.tokenizeFormData.addressCountry,
            validate: false,
          }

          const response = await staxJs.tokenize(extraDetails)
          const nickname = this.setName(this.tokenizeFormData.storeId, response.nickname)
          const data = {
            cardId: response.id,
            customerId: response.customer_id,
            nickname: nickname,
            companyId: this.companyId,
            storeId: this.storeId,
            cardType: 'ACH',
            method: response.method,
            cardLastFour: response.card_last_four,
            securityCode: null,
            cardExp: response.card_exp,
            cardExpDatetime: response.card_exp_datetime,
            isDefault: response.is_default,
            bankName: response.bank_name, // --
            bankType: response.bank_type, // --
            bankHolderType: response.bank_holder_type, // --
            isUsableInVt: response.is_usable_in_vt, // --
            updatedAt: response.updated_at, // --
            createdAt: response.created_at, // --
            binType: response.bin_type, //--
            hasCvv: response.has_cvv, // --
            customer: {
              id: response.customer.id,
              email: response.customer.email,
              phone: response.customer.phone,
              firstname: response.customer.firstname,
              lastname: response.customer.lastname,
              address_1: response.customer.address_1,
              address_2: response.customer.address_2,
              address_city: response.customer.address_city,
              address_country: response.customer.address_country,
              address_state: response.customer.address_state,
              address_zip: response.customer.address_zip,
              allow_invoice_credit_card_payments: response.customer.allow_invoice_credit_card_payments,
              cc_emails: response.customer.cc_emails,
              cc_sms: response.customer.cc_sms,
              company: response.customer.company,
              created_at: response.customer.created_at,
              updated_at: response.customer.updated_at,
              deleted_at: response.customer.deleted_at,
              gravatar: response.customer.gravatar,
              notes: response.customer.notes,
              reference: response.customer.reference,
              options: response.customer.options,
            },
          }
          const createResult = await staxService.createCreditCardToken(data)
          this.popupIns.hide()
          this.$emit('on-emit-close-tokenize-bank-account', true)
        }
      } catch (err) {
        const messeage = err.message || err
        Notify.error(messeage)
        this.popupIns.hide()
        this.$emit('on-emit-close-tokenize-bank-account', false)
      }
    },

    setName(storeId, val) {
      let name = val
      if (storeId) {
        const store = this.stores.find(c => c.id === storeId)
        name = `${name}, Store: ${store.name}`
        return name
      }
      name = `${name}, Store: All`
      return name
    },
  },
}
</script>

<style>

</style>
