const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  "Côte d'Ivoire",
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czechia',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'North Macedonia',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine State',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent & the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States of America',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]
const states = [
  'AL',
  'AK',
  'AS',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FM',
  'FL',
  'GA',
  'GU',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MH',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'MP',
  'OH',
  'OK',
  'OR',
  'PW',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VI',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
]
const inventories = [
  'FBM',
  'FBA',
  'Storage',
  'Forwarding',
  'Returns',
  'Removal Orders',
  'Dropship',
  'B2B',
  'WFS',
]

const countriesDetail = [
  {
    name: 'Aruba',
    code: 'AW',
    callingCode: '297',
  },
  {
    name: 'Afghanistan',
    code: 'AF',
    callingCode: '93',
  },
  {
    name: 'Angola',
    code: 'AO',
    callingCode: '244',
  },
  {
    name: 'Anguilla',
    code: 'AI',
    callingCode: '1264',
  },
  {
    name: 'Ãland Islands',
    code: 'AX',
    callingCode: '358',
  },
  {
    name: 'Albania',
    code: 'AL',
    callingCode: '355',
  },
  {
    name: 'Andorra',
    code: 'AD',
    callingCode: '376',
  },
  {
    name: 'United Arab Emirates',
    code: 'AE',
    callingCode: '971',
  },
  {
    name: 'Argentina',
    code: 'AR',
    callingCode: '54',
  },
  {
    name: 'Armenia',
    code: 'AM',
    callingCode: '374',
  },
  {
    name: 'American Samoa',
    code: 'AS',
    callingCode: '1684',
  },
  {
    name: 'Antigua and Barbuda',
    code: 'AG',
    callingCode: '1268',
  },
  {
    name: 'Australia',
    code: 'AU',
    callingCode: '61',
  },
  {
    name: 'Austria',
    code: 'AT',
    callingCode: '43',
  },
  {
    name: 'Azerbaijan',
    code: 'AZ',
    callingCode: '994',
  },
  {
    name: 'Burundi',
    code: 'BI',
    callingCode: '257',
  },
  {
    name: 'Belgium',
    code: 'BE',
    callingCode: '32',
  },
  {
    name: 'Benin',
    code: 'BJ',
    callingCode: '229',
  },
  {
    name: 'Burkina Faso',
    code: 'BF',
    callingCode: '226',
  },
  {
    name: 'Bangladesh',
    code: 'BD',
    callingCode: '880',
  },
  {
    name: 'Bulgaria',
    code: 'BG',
    callingCode: '359',
  },
  {
    name: 'Bahrain',
    code: 'BH',
    callingCode: '973',
  },
  {
    name: 'Bahamas',
    code: 'BS',
    callingCode: '1242',
  },
  {
    name: 'Bosnia and Herzegovina',
    code: 'BA',
    callingCode: '387',
  },
  {
    name: 'Saint BarthÃ©lemy',
    code: 'BL',
    callingCode: '590',
  },
  {
    name: 'Belarus',
    code: 'BY',
    callingCode: '375',
  },
  {
    name: 'Belize',
    code: 'BZ',
    callingCode: '501',
  },
  {
    name: 'Bermuda',
    code: 'BM',
    callingCode: '1441',
  },
  {
    name: 'Bolivia',
    code: 'BO',
    callingCode: '591',
  },
  {
    name: 'Brazil',
    code: 'BR',
    callingCode: '55',
  },
  {
    name: 'Barbados',
    code: 'BB',
    callingCode: '1246',
  },
  {
    name: 'Brunei',
    code: 'BN',
    callingCode: '673',
  },
  {
    name: 'Bhutan',
    code: 'BT',
    callingCode: '975',
  },
  {
    name: 'Botswana',
    code: 'BW',
    callingCode: '267',
  },
  {
    name: 'Central African Republic',
    code: 'CF',
    callingCode: '236',
  },
  {
    name: 'Canada',
    code: 'CA',
    callingCode: '1',
  },
  {
    name: 'Cocos (Keeling) Islands',
    code: 'CC',
    callingCode: '61',
  },
  {
    name: 'Switzerland',
    code: 'CH',
    callingCode: '41',
  },
  {
    name: 'Chile',
    code: 'CL',
    callingCode: '56',
  },
  {
    name: 'China',
    code: 'CN',
    callingCode: '86',
  },
  {
    name: 'Ivory Coast',
    code: 'CI',
    callingCode: '225',
  },
  {
    name: 'Cameroon',
    code: 'CM',
    callingCode: '237',
  },
  {
    name: 'DR Congo',
    code: 'CD',
    callingCode: '243',
  },
  {
    name: 'Republic of the Congo',
    code: 'CG',
    callingCode: '242',
  },
  {
    name: 'Cook Islands',
    code: 'CK',
    callingCode: '682',
  },
  {
    name: 'Colombia',
    code: 'CO',
    callingCode: '57',
  },
  {
    name: 'Comoros',
    code: 'KM',
    callingCode: '269',
  },
  {
    name: 'Cape Verde',
    code: 'CV',
    callingCode: '238',
  },
  {
    name: 'Costa Rica',
    code: 'CR',
    callingCode: '506',
  },
  {
    name: 'Cuba',
    code: 'CU',
    callingCode: '53',
  },
  {
    name: 'CuraÃ§ao',
    code: 'CW',
    callingCode: '5999',
  },
  {
    name: 'Christmas Island',
    code: 'CX',
    callingCode: '61',
  },
  {
    name: 'Cayman Islands',
    code: 'KY',
    callingCode: '1345',
  },
  {
    name: 'Cyprus',
    code: 'CY',
    callingCode: '357',
  },
  {
    name: 'Czechia',
    code: 'CZ',
    callingCode: '420',
  },
  {
    name: 'Germany',
    code: 'DE',
    callingCode: '49',
  },
  {
    name: 'Djibouti',
    code: 'DJ',
    callingCode: '253',
  },
  {
    name: 'Dominica',
    code: 'DM',
    callingCode: '1767',
  },
  {
    name: 'Denmark',
    code: 'DK',
    callingCode: '45',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    callingCode: '1809',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    callingCode: '1829',
  },
  {
    name: 'Dominican Republic',
    code: 'DO',
    callingCode: '1849',
  },
  {
    name: 'Algeria',
    code: 'DZ',
    callingCode: '213',
  },
  {
    name: 'Ecuador',
    code: 'EC',
    callingCode: '593',
  },
  {
    name: 'Egypt',
    code: 'EG',
    callingCode: '20',
  },
  {
    name: 'Eritrea',
    code: 'ER',
    callingCode: '291',
  },
  {
    name: 'Western Sahara',
    code: 'EH',
    callingCode: '212',
  },
  {
    name: 'Spain',
    code: 'ES',
    callingCode: '34',
  },
  {
    name: 'Estonia',
    code: 'EE',
    callingCode: '372',
  },
  {
    name: 'Ethiopia',
    code: 'ET',
    callingCode: '251',
  },
  {
    name: 'Finland',
    code: 'FI',
    callingCode: '358',
  },
  {
    name: 'Fiji',
    code: 'FJ',
    callingCode: '679',
  },
  {
    name: 'Falkland Islands',
    code: 'FK',
    callingCode: '500',
  },
  {
    name: 'France',
    code: 'FR',
    callingCode: '33',
  },
  {
    name: 'Faroe Islands',
    code: 'FO',
    callingCode: '298',
  },
  {
    name: 'Micronesia',
    code: 'FM',
    callingCode: '691',
  },
  {
    name: 'Gabon',
    code: 'GA',
    callingCode: '241',
  },
  {
    name: 'United Kingdom',
    code: 'GB',
    callingCode: '44',
  },
  {
    name: 'Georgia',
    code: 'GE',
    callingCode: '995',
  },
  {
    name: 'Guernsey',
    code: 'GG',
    callingCode: '44',
  },
  {
    name: 'Ghana',
    code: 'GH',
    callingCode: '233',
  },
  {
    name: 'Gibraltar',
    code: 'GI',
    callingCode: '350',
  },
  {
    name: 'Guinea',
    code: 'GN',
    callingCode: '224',
  },
  {
    name: 'Guadeloupe',
    code: 'GP',
    callingCode: '590',
  },
  {
    name: 'Gambia',
    code: 'GM',
    callingCode: '220',
  },
  {
    name: 'Guinea-Bissau',
    code: 'GW',
    callingCode: '245',
  },
  {
    name: 'Equatorial Guinea',
    code: 'GQ',
    callingCode: '240',
  },
  {
    name: 'Greece',
    code: 'GR',
    callingCode: '30',
  },
  {
    name: 'Grenada',
    code: 'GD',
    callingCode: '1473',
  },
  {
    name: 'Greenland',
    code: 'GL',
    callingCode: '299',
  },
  {
    name: 'Guatemala',
    code: 'GT',
    callingCode: '502',
  },
  {
    name: 'French Guiana',
    code: 'GF',
    callingCode: '594',
  },
  {
    name: 'Guam',
    code: 'GU',
    callingCode: '1671',
  },
  {
    name: 'Guyana',
    code: 'GY',
    callingCode: '592',
  },
  {
    name: 'Hong Kong',
    code: 'HK',
    callingCode: '852',
  },
  {
    name: 'Honduras',
    code: 'HN',
    callingCode: '504',
  },
  {
    name: 'Croatia',
    code: 'HR',
    callingCode: '385',
  },
  {
    name: 'Haiti',
    code: 'HT',
    callingCode: '509',
  },
  {
    name: 'Hungary',
    code: 'HU',
    callingCode: '36',
  },
  {
    name: 'Indonesia',
    code: 'ID',
    callingCode: '62',
  },
  {
    name: 'Isle of Man',
    code: 'IM',
    callingCode: '44',
  },
  {
    name: 'India',
    code: 'IN',
    callingCode: '91',
  },
  {
    name: 'British Indian Ocean Territory',
    code: 'IO',
    callingCode: '246',
  },
  {
    name: 'Ireland',
    code: 'IE',
    callingCode: '353',
  },
  {
    name: 'Iran',
    code: 'IR',
    callingCode: '98',
  },
  {
    name: 'Iraq',
    code: 'IQ',
    callingCode: '964',
  },
  {
    name: 'Iceland',
    code: 'IS',
    callingCode: '354',
  },
  {
    name: 'Israel',
    code: 'IL',
    callingCode: '972',
  },
  {
    name: 'Italy',
    code: 'IT',
    callingCode: '39',
  },
  {
    name: 'Jamaica',
    code: 'JM',
    callingCode: '1876',
  },
  {
    name: 'Jersey',
    code: 'JE',
    callingCode: '44',
  },
  {
    name: 'Jordan',
    code: 'JO',
    callingCode: '962',
  },
  {
    name: 'Japan',
    code: 'JP',
    callingCode: '81',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    callingCode: '76',
  },
  {
    name: 'Kazakhstan',
    code: 'KZ',
    callingCode: '77',
  },
  {
    name: 'Kenya',
    code: 'KE',
    callingCode: '254',
  },
  {
    name: 'Kyrgyzstan',
    code: 'KG',
    callingCode: '996',
  },
  {
    name: 'Cambodia',
    code: 'KH',
    callingCode: '855',
  },
  {
    name: 'Kiribati',
    code: 'KI',
    callingCode: '686',
  },
  {
    name: 'Saint Kitts and Nevis',
    code: 'KN',
    callingCode: '1869',
  },
  {
    name: 'South Korea',
    code: 'KR',
    callingCode: '82',
  },
  {
    name: 'Kosovo',
    code: 'XK',
    callingCode: '383',
  },
  {
    name: 'Kuwait',
    code: 'KW',
    callingCode: '965',
  },
  {
    name: 'Laos',
    code: 'LA',
    callingCode: '856',
  },
  {
    name: 'Lebanon',
    code: 'LB',
    callingCode: '961',
  },
  {
    name: 'Liberia',
    code: 'LR',
    callingCode: '231',
  },
  {
    name: 'Libya',
    code: 'LY',
    callingCode: '218',
  },
  {
    name: 'Saint Lucia',
    code: 'LC',
    callingCode: '1758',
  },
  {
    name: 'Liechtenstein',
    code: 'LI',
    callingCode: '423',
  },
  {
    name: 'Sri Lanka',
    code: 'LK',
    callingCode: '94',
  },
  {
    name: 'Lesotho',
    code: 'LS',
    callingCode: '266',
  },
  {
    name: 'Lithuania',
    code: 'LT',
    callingCode: '370',
  },
  {
    name: 'Luxembourg',
    code: 'LU',
    callingCode: '352',
  },
  {
    name: 'Latvia',
    code: 'LV',
    callingCode: '371',
  },
  {
    name: 'Macau',
    code: 'MO',
    callingCode: '853',
  },
  {
    name: 'Saint Martin',
    code: 'MF',
    callingCode: '590',
  },
  {
    name: 'Morocco',
    code: 'MA',
    callingCode: '212',
  },
  {
    name: 'Monaco',
    code: 'MC',
    callingCode: '377',
  },
  {
    name: 'Moldova',
    code: 'MD',
    callingCode: '373',
  },
  {
    name: 'Madagascar',
    code: 'MG',
    callingCode: '261',
  },
  {
    name: 'Maldives',
    code: 'MV',
    callingCode: '960',
  },
  {
    name: 'Mexico',
    code: 'MX',
    callingCode: '52',
  },
  {
    name: 'Marshall Islands',
    code: 'MH',
    callingCode: '692',
  },
  {
    name: 'Macedonia',
    code: 'MK',
    callingCode: '389',
  },
  {
    name: 'Mali',
    code: 'ML',
    callingCode: '223',
  },
  {
    name: 'Malta',
    code: 'MT',
    callingCode: '356',
  },
  {
    name: 'Myanmar',
    code: 'MM',
    callingCode: '95',
  },
  {
    name: 'Montenegro',
    code: 'ME',
    callingCode: '382',
  },
  {
    name: 'Mongolia',
    code: 'MN',
    callingCode: '976',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
    callingCode: '1670',
  },
  {
    name: 'Mozambique',
    code: 'MZ',
    callingCode: '258',
  },
  {
    name: 'Mauritania',
    code: 'MR',
    callingCode: '222',
  },
  {
    name: 'Montserrat',
    code: 'MS',
    callingCode: '1664',
  },
  {
    name: 'Martinique',
    code: 'MQ',
    callingCode: '596',
  },
  {
    name: 'Mauritius',
    code: 'MU',
    callingCode: '230',
  },
  {
    name: 'Malawi',
    code: 'MW',
    callingCode: '265',
  },
  {
    name: 'Malaysia',
    code: 'MY',
    callingCode: '60',
  },
  {
    name: 'Mayotte',
    code: 'YT',
    callingCode: '262',
  },
  {
    name: 'Namibia',
    code: 'NA',
    callingCode: '264',
  },
  {
    name: 'New Caledonia',
    code: 'NC',
    callingCode: '687',
  },
  {
    name: 'Niger',
    code: 'NE',
    callingCode: '227',
  },
  {
    name: 'Norfolk Island',
    code: 'NF',
    callingCode: '672',
  },
  {
    name: 'Nigeria',
    code: 'NG',
    callingCode: '234',
  },
  {
    name: 'Nicaragua',
    code: 'NI',
    callingCode: '505',
  },
  {
    name: 'Niue',
    code: 'NU',
    callingCode: '683',
  },
  {
    name: 'Netherlands',
    code: 'NL',
    callingCode: '31',
  },
  {
    name: 'Norway',
    code: 'NO',
    callingCode: '47',
  },
  {
    name: 'Nepal',
    code: 'NP',
    callingCode: '977',
  },
  {
    name: 'Nauru',
    code: 'NR',
    callingCode: '674',
  },
  {
    name: 'New Zealand',
    code: 'NZ',
    callingCode: '64',
  },
  {
    name: 'Oman',
    code: 'OM',
    callingCode: '968',
  },
  {
    name: 'Pakistan',
    code: 'PK',
    callingCode: '92',
  },
  {
    name: 'Panama',
    code: 'PA',
    callingCode: '507',
  },
  {
    name: 'Pitcairn Islands',
    code: 'PN',
    callingCode: '64',
  },
  {
    name: 'Peru',
    code: 'PE',
    callingCode: '51',
  },
  {
    name: 'Philippines',
    code: 'PH',
    callingCode: '63',
  },
  {
    name: 'Palau',
    code: 'PW',
    callingCode: '680',
  },
  {
    name: 'Papua New Guinea',
    code: 'PG',
    callingCode: '675',
  },
  {
    name: 'Poland',
    code: 'PL',
    callingCode: '48',
  },
  {
    name: 'Puerto Rico',
    code: 'PRI',
    callingCode: '1787',
  },
  {
    name: 'Puerto Rico',
    code: 'PRI',
    callingCode: '1939',
  },
  {
    name: 'North Korea',
    code: 'KP',
    callingCode: '850',
  },
  {
    name: 'Portugal',
    code: 'PT',
    callingCode: '351',
  },
  {
    name: 'Paraguay',
    code: 'PY',
    callingCode: '595',
  },
  {
    name: 'Palestine',
    code: 'PS',
    callingCode: '970',
  },
  {
    name: 'French Polynesia',
    code: 'PF',
    callingCode: '689',
  },
  {
    name: 'Qatar',
    code: 'QA',
    callingCode: '974',
  },
  {
    name: 'RÃ©union',
    code: 'RE',
    callingCode: '262',
  },
  {
    name: 'Romania',
    code: 'RO',
    callingCode: '40',
  },
  {
    name: 'Russia',
    code: 'RU',
    callingCode: '7',
  },
  {
    name: 'Rwanda',
    code: 'RW',
    callingCode: '250',
  },
  {
    name: 'Saudi Arabia',
    code: 'SA',
    callingCode: '966',
  },
  {
    name: 'Sudan',
    code: 'SD',
    callingCode: '249',
  },
  {
    name: 'Senegal',
    code: 'SN',
    callingCode: '221',
  },
  {
    name: 'Singapore',
    code: 'SG',
    callingCode: '65',
  },
  {
    name: 'South Georgia',
    code: 'GS',
    callingCode: '500',
  },
  {
    name: 'Svalbard and Jan Mayen',
    code: 'SJ',
    callingCode: '4779',
  },
  {
    name: 'Solomon Islands',
    code: 'SB',
    callingCode: '677',
  },
  {
    name: 'Sierra Leone',
    code: 'SL',
    callingCode: '232',
  },
  {
    name: 'El Salvador',
    code: 'SV',
    callingCode: '503',
  },
  {
    name: 'San Marino',
    code: 'SM',
    callingCode: '378',
  },
  {
    name: 'Somalia',
    code: 'SO',
    callingCode: '252',
  },
  {
    name: 'Saint Pierre and Miquelon',
    code: 'PM',
    callingCode: '508',
  },
  {
    name: 'Serbia',
    code: 'RS',
    callingCode: '381',
  },
  {
    name: 'South Sudan',
    code: 'SS',
    callingCode: '211',
  },
  {
    name: 'SÃ£o TomÃ© and PrÃ­ncipe',
    code: 'ST',
    callingCode: '239',
  },
  {
    name: 'Suriname',
    code: 'SR',
    callingCode: '597',
  },
  {
    name: 'Slovakia',
    code: 'SK',
    callingCode: '421',
  },
  {
    name: 'Slovenia',
    code: 'SI',
    callingCode: '386',
  },
  {
    name: 'Sweden',
    code: 'SE',
    callingCode: '46',
  },
  {
    name: 'Swaziland',
    code: 'SZ',
    callingCode: '268',
  },
  {
    name: 'Sint Maarten',
    code: 'SX',
    callingCode: '1721',
  },
  {
    name: 'Seychelles',
    code: 'SC',
    callingCode: '248',
  },
  {
    name: 'Syria',
    code: 'SY',
    callingCode: '963',
  },
  {
    name: 'Turks and Caicos Islands',
    code: 'TC',
    callingCode: '1649',
  },
  {
    name: 'Chad',
    code: 'TD',
    callingCode: '235',
  },
  {
    name: 'Togo',
    code: 'TG',
    callingCode: '228',
  },
  {
    name: 'Thailand',
    code: 'TH',
    callingCode: '66',
  },
  {
    name: 'Tajikistan',
    code: 'TJ',
    callingCode: '992',
  },
  {
    name: 'Tokelau',
    code: 'TK',
    callingCode: '690',
  },
  {
    name: 'Turkmenistan',
    code: 'TM',
    callingCode: '993',
  },
  {
    name: 'Timor-Leste',
    code: 'TL',
    callingCode: '670',
  },
  {
    name: 'Tonga',
    code: 'TO',
    callingCode: '676',
  },
  {
    name: 'Trinidad and Tobago',
    code: 'TT',
    callingCode: '1868',
  },
  {
    name: 'Tunisia',
    code: 'TN',
    callingCode: '216',
  },
  {
    name: 'Turkey',
    code: 'TR',
    callingCode: '90',
  },
  {
    name: 'Tuvalu',
    code: 'TV',
    callingCode: '688',
  },
  {
    name: 'Taiwan',
    code: 'TW',
    callingCode: '886',
  },
  {
    name: 'Tanzania',
    code: 'TZ',
    callingCode: '255',
  },
  {
    name: 'Uganda',
    code: 'UG',
    callingCode: '256',
  },
  {
    name: 'Ukraine',
    code: 'UA',
    callingCode: '380',
  },
  {
    name: 'Uruguay',
    code: 'UY',
    callingCode: '598',
  },
  {
    name: 'United States of America',
    code: 'USA',
    callingCode: '1',
  },
  {
    name: 'Uzbekistan',
    code: 'UZ',
    callingCode: '998',
  },
  {
    name: 'Vatican City',
    code: 'VA',
    callingCode: '3906698',
  },
  {
    name: 'Vatican City',
    code: 'VA',
    callingCode: '379',
  },
  {
    name: 'Saint Vincent and the Grenadines',
    code: 'VC',
    callingCode: '1784',
  },
  {
    name: 'Venezuela',
    code: 'VE',
    callingCode: '58',
  },
  {
    name: 'British Virgin Islands',
    code: 'VG',
    callingCode: '1284',
  },
  {
    name: 'United States Virgin Islands',
    code: 'VI',
    callingCode: '1340',
  },
  {
    name: 'Vietnam',
    code: 'VN',
    callingCode: '84',
  },
  {
    name: 'Vanuatu',
    code: 'VU',
    callingCode: '678',
  },
  {
    name: 'Wallis and Futuna',
    code: 'WF',
    callingCode: '681',
  },
  {
    name: 'Samoa',
    code: 'WS',
    callingCode: '685',
  },
  {
    name: 'Yemen',
    code: 'YE',
    callingCode: '967',
  },
  {
    name: 'South Africa',
    code: 'ZA',
    callingCode: '27',
  },
  {
    name: 'Zambia',
    code: 'ZM',
    callingCode: '260',
  },
  {
    name: 'Zimbabwe',
    code: 'ZW',
    callingCode: '263',
  },
]

const statesByCountry = {
  CA: ['AB', 'BC', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
  USA: [
    'AL',
    'AK',
    'AS',
    'AZ',
    'AR',
    'CA',
    'CO',
    'CT',
    'DE',
    'DC',
    'FM',
    'FL',
    'GA',
    'GU',
    'HI',
    'ID',
    'IL',
    'IN',
    'IA',
    'KS',
    'KY',
    'LA',
    'ME',
    'MH',
    'MD',
    'MA',
    'MI',
    'MN',
    'MS',
    'MO',
    'MT',
    'NE',
    'NV',
    'NH',
    'NJ',
    'NM',
    'NY',
    'NC',
    'ND',
    'MP',
    'OH',
    'OK',
    'OR',
    'PW',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TX',
    'UT',
    'VT',
    'VI',
    'VA',
    'WA',
    'WV',
    'WI',
    'WY',
  ],

}

const countriesWithRegions = [{
  name: 'Afghanistan', code: 'AF', code2: 'AFG', region: 'Asia',
}, {
  name: 'Åland Islands', code: 'AX', code2: 'ALA', region: 'Europe',
}, {
  name: 'Albania', code: 'AL', code2: 'ALB', region: 'Europe',
}, {
  name: 'Algeria', code: 'DZ', code2: 'DZA', region: 'Africa',
}, {
  name: 'American Samoa', code: 'AS', code2: 'ASM', region: 'Oceania',
}, {
  name: 'Andorra', code: 'AD', code2: 'AND', region: 'Europe',
}, {
  name: 'Angola', code: 'AO', code2: 'AGO', region: 'Africa',
}, {
  name: 'Anguilla', code: 'AI', code2: 'AIA', region: 'Americas',
}, {
  name: 'Antarctica', code: 'AQ', code2: 'ATA', region: '',
}, {
  name: 'Antigua and Barbuda', code: 'AG', code2: 'ATG', region: 'Americas',
}, {
  name: 'Argentina', code: 'AR', code2: 'ARG', region: 'Americas',
}, {
  name: 'Armenia', code: 'AM', code2: 'ARM', region: 'Asia',
}, {
  name: 'Aruba', code: 'AW', code2: 'ABW', region: 'Americas',
}, {
  name: 'Australia', code: 'AU', code2: 'AUS', region: 'Oceania',
}, {
  name: 'Austria', code: 'AT', code2: 'AUT', region: 'Europe',
}, {
  name: 'Azerbaijan', code: 'AZ', code2: 'AZE', region: 'Asia',
}, {
  name: 'Bahamas', code: 'BS', code2: 'BHS', region: 'Americas',
}, {
  name: 'Bahrain', code: 'BH', code2: 'BHR', region: 'Asia',
}, {
  name: 'Bangladesh', code: 'BD', code2: 'BGD', region: 'Asia',
}, {
  name: 'Barbados', code: 'BB', code2: 'BRB', region: 'Americas',
}, {
  name: 'Belarus', code: 'BY', code2: 'BLR', region: 'Europe',
}, {
  name: 'Belgium', code: 'BE', code2: 'BEL', region: 'Europe',
}, {
  name: 'Belize', code: 'BZ', code2: 'BLZ', region: 'Americas',
}, {
  name: 'Benin', code: 'BJ', code2: 'BEN', region: 'Africa',
}, {
  name: 'Bermuda', code: 'BM', code2: 'BMU', region: 'Americas',
}, {
  name: 'Bhutan', code: 'BT', code2: 'BTN', region: 'Asia',
}, {
  name: 'Bolivia (Plurinational State of)', code: 'BO', code2: 'BOL', region: 'Americas',
}, {
  name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', code2: 'BES', region: 'Americas',
}, {
  name: 'Bosnia and Herzegovina', code: 'BA', code2: 'BIH', region: 'Europe',
}, {
  name: 'Botswana', code: 'BW', code2: 'BWA', region: 'Africa',
}, {
  name: 'Bouvet Island', code: 'BV', code2: 'BVT', region: 'Americas',
}, {
  name: 'Brazil', code: 'BR', code2: 'BRA', region: 'Americas',
}, {
  name: 'British Indian Ocean Territory', code: 'IO', code2: 'IOT', region: 'Africa',
}, {
  name: 'Brunei Darussalam', code: 'BN', code2: 'BRN', region: 'Asia',
}, {
  name: 'Bulgaria', code: 'BG', code2: 'BGR', region: 'Europe',
}, {
  name: 'Burkina Faso', code: 'BF', code2: 'BFA', region: 'Africa',
}, {
  name: 'Burundi', code: 'BI', code2: 'BDI', region: 'Africa',
}, {
  name: 'Cabo Verde', code: 'CV', code2: 'CPV', region: 'Africa',
}, {
  name: 'Cambodia', code: 'KH', code2: 'KHM', region: 'Asia',
}, {
  name: 'Cameroon', code: 'CM', code2: 'CMR', region: 'Africa',
}, {
  name: 'Canada', code: 'CA', code2: 'CAN', region: 'Americas',
}, {
  name: 'Cayman Islands', code: 'KY', code2: 'CYM', region: 'Americas',
}, {
  name: 'Central African Republic', code: 'CF', code2: 'CAF', region: 'Africa',
}, {
  name: 'Chad', code: 'TD', code2: 'TCD', region: 'Africa',
}, {
  name: 'Chile', code: 'CL', code2: 'CHL', region: 'Americas',
}, {
  name: 'China', code: 'CN', code2: 'CHN', region: 'Asia',
}, {
  name: 'Christmas Island', code: 'CX', code2: 'CXR', region: 'Oceania',
}, {
  name: 'Cocos (Keeling) Islands', code: 'CC', code2: 'CCK', region: 'Oceania',
}, {
  name: 'Colombia', code: 'CO', code2: 'COL', region: 'Americas',
}, {
  name: 'Comoros', code: 'KM', code2: 'COM', region: 'Africa',
}, {
  name: 'Congo', code: 'CG', code2: 'COG', region: 'Africa',
}, {
  name: 'Congo, Democratic Republic of the', code: 'CD', code2: 'COD', region: 'Africa',
}, {
  name: 'Cook Islands', code: 'CK', code2: 'COK', region: 'Oceania',
}, {
  name: 'Costa Rica', code: 'CR', code2: 'CRI', region: 'Americas',
}, {
  name: "Côte d'Ivoire", code: 'CI', code2: 'CIV', region: 'Africa',
}, {
  name: 'Croatia', code: 'HR', code2: 'HRV', region: 'Europe',
}, {
  name: 'Cuba', code: 'CU', code2: 'CUB', region: 'Americas',
}, {
  name: 'Curaçao', code: 'CW', code2: 'CUW', region: 'Americas',
}, {
  name: 'Cyprus', code: 'CY', code2: 'CYP', region: 'Asia',
}, {
  name: 'Czechia', code: 'CZ', code2: 'CZE', region: 'Europe',
}, {
  name: 'Denmark', code: 'DK', code2: 'DNK', region: 'Europe',
}, {
  name: 'Djibouti', code: 'DJ', code2: 'DJI', region: 'Africa',
}, {
  name: 'Dominica', code: 'DM', code2: 'DMA', region: 'Americas',
}, {
  name: 'Dominican Republic', code: 'DO', code2: 'DOM', region: 'Americas',
}, {
  name: 'Ecuador', code: 'EC', code2: 'ECU', region: 'Americas',
}, {
  name: 'Egypt', code: 'EG', code2: 'EGY', region: 'Africa',
}, {
  name: 'El Salvador', code: 'SV', code2: 'SLV', region: 'Americas',
}, {
  name: 'Equatorial Guinea', code: 'GQ', code2: 'GNQ', region: 'Africa',
}, {
  name: 'Eritrea', code: 'ER', code2: 'ERI', region: 'Africa',
}, {
  name: 'Estonia', code: 'EE', code2: 'EST', region: 'Europe',
}, {
  name: 'Eswatini', code: 'SZ', code2: 'SWZ', region: 'Africa',
}, {
  name: 'Ethiopia', code: 'ET', code2: 'ETH', region: 'Africa',
}, {
  name: 'Falkland Islands (Malvinas)', code: 'FK', code2: 'FLK', region: 'Americas',
}, {
  name: 'Faroe Islands', code: 'FO', code2: 'FRO', region: 'Europe',
}, {
  name: 'Fiji', code: 'FJ', code2: 'FJI', region: 'Oceania',
}, {
  name: 'Finland', code: 'FI', code2: 'FIN', region: 'Europe',
}, {
  name: 'France', code: 'FR', code2: 'FRA', region: 'Europe',
}, {
  name: 'French Guiana', code: 'GF', code2: 'GUF', region: 'Americas',
}, {
  name: 'French Polynesia', code: 'PF', code2: 'PYF', region: 'Oceania',
}, {
  name: 'French Southern Territories', code: 'TF', code2: 'ATF', region: 'Africa',
}, {
  name: 'Gabon', code: 'GA', code2: 'GAB', region: 'Africa',
}, {
  name: 'Gambia', code: 'GM', code2: 'GMB', region: 'Africa',
}, {
  name: 'Georgia', code: 'GE', code2: 'GEO', region: 'Asia',
}, {
  name: 'Germany', code: 'DE', code2: 'DEU', region: 'Europe',
}, {
  name: 'Ghana', code: 'GH', code2: 'GHA', region: 'Africa',
}, {
  name: 'Gibraltar', code: 'GI', code2: 'GIB', region: 'Europe',
}, {
  name: 'Greece', code: 'GR', code2: 'GRC', region: 'Europe',
}, {
  name: 'Greenland', code: 'GL', code2: 'GRL', region: 'Americas',
}, {
  name: 'Grenada', code: 'GD', code2: 'GRD', region: 'Americas',
}, {
  name: 'Guadeloupe', code: 'GP', code2: 'GLP', region: 'Americas',
}, {
  name: 'Guam', code: 'GU', code2: 'GUM', region: 'Oceania',
}, {
  name: 'Guatemala', code: 'GT', code2: 'GTM', region: 'Americas',
}, {
  name: 'Guernsey', code: 'GG', code2: 'GGY', region: 'Europe',
}, {
  name: 'Guinea', code: 'GN', code2: 'GIN', region: 'Africa',
}, {
  name: 'Guinea-Bissau', code: 'GW', code2: 'GNB', region: 'Africa',
}, {
  name: 'Guyana', code: 'GY', code2: 'GUY', region: 'Americas',
}, {
  name: 'Haiti', code: 'HT', code2: 'HTI', region: 'Americas',
}, {
  name: 'Heard Island and McDonald Islands', code: 'HM', code2: 'HMD', region: 'Oceania',
}, {
  name: 'Holy See', code: 'VA', code2: 'VAT', region: 'Europe',
}, {
  name: 'Honduras', code: 'HN', code2: 'HND', region: 'Americas',
}, {
  name: 'Hong Kong', code: 'HK', code2: 'HKG', region: 'Asia',
}, {
  name: 'Hungary', code: 'HU', code2: 'HUN', region: 'Europe',
}, {
  name: 'Iceland', code: 'IS', code2: 'ISL', region: 'Europe',
}, {
  name: 'India', code: 'IN', code2: 'IND', region: 'Asia',
}, {
  name: 'Indonesia', code: 'ID', code2: 'IDN', region: 'Asia',
}, {
  name: 'Iran (Islamic Republic of)', code: 'IR', code2: 'IRN', region: 'Asia',
}, {
  name: 'Iraq', code: 'IQ', code2: 'IRQ', region: 'Asia',
}, {
  name: 'Ireland', code: 'IE', code2: 'IRL', region: 'Europe',
}, {
  name: 'Isle of Man', code: 'IM', code2: 'IMN', region: 'Europe',
}, {
  name: 'Israel', code: 'IL', code2: 'ISR', region: 'Asia',
}, {
  name: 'Italy', code: 'IT', code2: 'ITA', region: 'Europe',
}, {
  name: 'Jamaica', code: 'JM', code2: 'JAM', region: 'Americas',
}, {
  name: 'Japan', code: 'JP', code2: 'JPN', region: 'Asia',
}, {
  name: 'Jersey', code: 'JE', code2: 'JEY', region: 'Europe',
}, {
  name: 'Jordan', code: 'JO', code2: 'JOR', region: 'Asia',
}, {
  name: 'Kazakhstan', code: 'KZ', code2: 'KAZ', region: 'Asia',
}, {
  name: 'Kenya', code: 'KE', code2: 'KEN', region: 'Africa',
}, {
  name: 'Kiribati', code: 'KI', code2: 'KIR', region: 'Oceania',
}, {
  name: "Korea (Democratic People's Republic of)", code: 'KP', code2: 'PRK', region: 'Asia',
}, {
  name: 'Korea, Republic of', code: 'KR', code2: 'KOR', region: 'Asia',
}, {
  name: 'Kuwait', code: 'KW', code2: 'KWT', region: 'Asia',
}, {
  name: 'Kyrgyzstan', code: 'KG', code2: 'KGZ', region: 'Asia',
}, {
  name: "Lao People's Democratic Republic", code: 'LA', code2: 'LAO', region: 'Asia',
}, {
  name: 'Latvia', code: 'LV', code2: 'LVA', region: 'Europe',
}, {
  name: 'Lebanon', code: 'LB', code2: 'LBN', region: 'Asia',
}, {
  name: 'Lesotho', code: 'LS', code2: 'LSO', region: 'Africa',
}, {
  name: 'Liberia', code: 'LR', code2: 'LBR', region: 'Africa',
}, {
  name: 'Libya', code: 'LY', code2: 'LBY', region: 'Africa',
}, {
  name: 'Liechtenstein', code: 'LI', code2: 'LIE', region: 'Europe',
}, {
  name: 'Lithuania', code: 'LT', code2: 'LTU', region: 'Europe',
}, {
  name: 'Luxembourg', code: 'LU', code2: 'LUX', region: 'Europe',
}, {
  name: 'Macao', code: 'MO', code2: 'MAC', region: 'Asia',
}, {
  name: 'Madagascar', code: 'MG', code2: 'MDG', region: 'Africa',
}, {
  name: 'Malawi', code: 'MW', code2: 'MWI', region: 'Africa',
}, {
  name: 'Malaysia', code: 'MY', code2: 'MYS', region: 'Asia',
}, {
  name: 'Maldives', code: 'MV', code2: 'MDV', region: 'Asia',
}, {
  name: 'Mali', code: 'ML', code2: 'MLI', region: 'Africa',
}, {
  name: 'Malta', code: 'MT', code2: 'MLT', region: 'Europe',
}, {
  name: 'Marshall Islands', code: 'MH', code2: 'MHL', region: 'Oceania',
}, {
  name: 'Martinique', code: 'MQ', code2: 'MTQ', region: 'Americas',
}, {
  name: 'Mauritania', code: 'MR', code2: 'MRT', region: 'Africa',
}, {
  name: 'Mauritius', code: 'MU', code2: 'MUS', region: 'Africa',
}, {
  name: 'Mayotte', code: 'YT', code2: 'MYT', region: 'Africa',
}, {
  name: 'Mexico', code: 'MX', code2: 'MEX', region: 'Americas',
}, {
  name: 'Micronesia (Federated States of)', code: 'FM', code2: 'FSM', region: 'Oceania',
}, {
  name: 'Moldova, Republic of', code: 'MD', code2: 'MDA', region: 'Europe',
}, {
  name: 'Monaco', code: 'MC', code2: 'MCO', region: 'Europe',
}, {
  name: 'Mongolia', code: 'MN', code2: 'MNG', region: 'Asia',
}, {
  name: 'Montenegro', code: 'ME', code2: 'MNE', region: 'Europe',
}, {
  name: 'Montserrat', code: 'MS', code2: 'MSR', region: 'Americas',
}, {
  name: 'Morocco', code: 'MA', code2: 'MAR', region: 'Africa',
}, {
  name: 'Mozambique', code: 'MZ', code2: 'MOZ', region: 'Africa',
}, {
  name: 'Myanmar', code: 'MM', code2: 'MMR', region: 'Asia',
}, {
  name: 'Namibia', code: 'NA', code2: 'NAM', region: 'Africa',
}, {
  name: 'Nauru', code: 'NR', code2: 'NRU', region: 'Oceania',
}, {
  name: 'Nepal', code: 'NP', code2: 'NPL', region: 'Asia',
}, {
  name: 'Netherlands', code: 'NL', code2: 'NLD', region: 'Europe',
}, {
  name: 'New Caledonia', code: 'NC', code2: 'NCL', region: 'Oceania',
}, {
  name: 'New Zealand', code: 'NZ', code2: 'NZL', region: 'Oceania',
}, {
  name: 'Nicaragua', code: 'NI', code2: 'NIC', region: 'Americas',
}, {
  name: 'Niger', code: 'NE', code2: 'NER', region: 'Africa',
}, {
  name: 'Nigeria', code: 'NG', code2: 'NGA', region: 'Africa',
}, {
  name: 'Niue', code: 'NU', code2: 'NIU', region: 'Oceania',
}, {
  name: 'Norfolk Island', code: 'NF', code2: 'NFK', region: 'Oceania',
}, {
  name: 'North Macedonia', code: 'MK', code2: 'MKD', region: 'Europe',
}, {
  name: 'Northern Mariana Islands', code: 'MP', code2: 'MNP', region: 'Oceania',
}, {
  name: 'Norway', code: 'NO', code2: 'NOR', region: 'Europe',
}, {
  name: 'Oman', code: 'OM', code2: 'OMN', region: 'Asia',
}, {
  name: 'Pakistan', code: 'PK', code2: 'PAK', region: 'Asia',
}, {
  name: 'Palau', code: 'PW', code2: 'PLW', region: 'Oceania',
}, {
  name: 'Palestine, State of', code: 'PS', code2: 'PSE', region: 'Asia',
}, {
  name: 'Panama', code: 'PA', code2: 'PAN', region: 'Americas',
}, {
  name: 'Papua New Guinea', code: 'PG', code2: 'PNG', region: 'Oceania',
}, {
  name: 'Paraguay', code: 'PY', code2: 'PRY', region: 'Americas',
}, {
  name: 'Peru', code: 'PE', code2: 'PER', region: 'Americas',
}, {
  name: 'Philippines', code: 'PH', code2: 'PHL', region: 'Asia',
}, {
  name: 'Pitcairn', code: 'PN', code2: 'PCN', region: 'Oceania',
}, {
  name: 'Poland', code: 'PL', code2: 'POL', region: 'Europe',
}, {
  name: 'Portugal', code: 'PT', code2: 'PRT', region: 'Europe',
}, {
  name: 'Puerto Rico', code: 'PR', code2: 'PRI', region: 'Americas',
}, {
  name: 'Qatar', code: 'QA', code2: 'QAT', region: 'Asia',
}, {
  name: 'Réunion', code: 'RE', code2: 'REU', region: 'Africa',
}, {
  name: 'Romania', code: 'RO', code2: 'ROU', region: 'Europe',
}, {
  name: 'Russian Federation', code: 'RU', code2: 'RUS', region: 'Europe',
}, {
  name: 'Rwanda', code: 'RW', code2: 'RWA', region: 'Africa',
}, {
  name: 'Saint Barthélemy', code: 'BL', code2: 'BLM', region: 'Americas',
}, {
  name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH', code2: 'SHN', region: 'Africa',
}, {
  name: 'Saint Kitts and Nevis', code: 'KN', code2: 'KNA', region: 'Americas',
}, {
  name: 'Saint Lucia', code: 'LC', code2: 'LCA', region: 'Americas',
}, {
  name: 'Saint Martin (French part)', code: 'MF', code2: 'MAF', region: 'Americas',
}, {
  name: 'Saint Pierre and Miquelon', code: 'PM', code2: 'SPM', region: 'Americas',
}, {
  name: 'Saint Vincent and the Grenadines', code: 'VC', code2: 'VCT', region: 'Americas',
}, {
  name: 'Samoa', code: 'WS', code2: 'WSM', region: 'Oceania',
}, {
  name: 'San Marino', code: 'SM', code2: 'SMR', region: 'Europe',
}, {
  name: 'Sao Tome and Principe', code: 'ST', code2: 'STP', region: 'Africa',
}, {
  name: 'Saudi Arabia', code: 'SA', code2: 'SAU', region: 'Asia',
}, {
  name: 'Senegal', code: 'SN', code2: 'SEN', region: 'Africa',
}, {
  name: 'Serbia', code: 'RS', code2: 'SRB', region: 'Europe',
}, {
  name: 'Seychelles', code: 'SC', code2: 'SYC', region: 'Africa',
}, {
  name: 'Sierra Leone', code: 'SL', code2: 'SLE', region: 'Africa',
}, {
  name: 'Singapore', code: 'SG', code2: 'SGP', region: 'Asia',
}, {
  name: 'Sint Maarten (Dutch part)', code: 'SX', code2: 'SXM', region: 'Americas',
}, {
  name: 'Slovakia', code: 'SK', code2: 'SVK', region: 'Europe',
}, {
  name: 'Slovenia', code: 'SI', code2: 'SVN', region: 'Europe',
}, {
  name: 'Solomon Islands', code: 'SB', code2: 'SLB', region: 'Oceania',
}, {
  name: 'Somalia', code: 'SO', code2: 'SOM', region: 'Africa',
}, {
  name: 'South Africa', code: 'ZA', code2: 'ZAF', region: 'Africa',
}, {
  name: 'South Georgia and the South Sandwich Islands', code: 'GS', code2: 'SGS', region: 'Americas',
}, {
  name: 'South Sudan', code: 'SS', code2: 'SSD', region: 'Africa',
}, {
  name: 'Spain', code: 'ES', code2: 'ESP', region: 'Europe',
}, {
  name: 'Sri Lanka', code: 'LK', code2: 'LKA', region: 'Asia',
}, {
  name: 'Sudan', code: 'SD', code2: 'SDN', region: 'Africa',
}, {
  name: 'Suriname', code: 'SR', code2: 'SUR', region: 'Americas',
}, {
  name: 'Svalbard and Jan Mayen', code: 'SJ', code2: 'SJM', region: 'Europe',
}, {
  name: 'Sweden', code: 'SE', code2: 'SWE', region: 'Europe',
}, {
  name: 'Switzerland', code: 'CH', code2: 'CHE', region: 'Europe',
}, {
  name: 'Syrian Arab Republic', code: 'SY', code2: 'SYR', region: 'Asia',
}, {
  name: 'Taiwan, Province of China', code: 'TW', code2: 'TWN', region: 'Asia',
}, {
  name: 'Tajikistan', code: 'TJ', code2: 'TJK', region: 'Asia',
}, {
  name: 'Tanzania, United Republic of', code: 'TZ', code2: 'TZA', region: 'Africa',
}, {
  name: 'Thailand', code: 'TH', code2: 'THA', region: 'Asia',
}, {
  name: 'Timor-Leste', code: 'TL', code2: 'TLS', region: 'Asia',
}, {
  name: 'Togo', code: 'TG', code2: 'TGO', region: 'Africa',
}, {
  name: 'Tokelau', code: 'TK', code2: 'TKL', region: 'Oceania',
}, {
  name: 'Tonga', code: 'TO', code2: 'TON', region: 'Oceania',
}, {
  name: 'Trinidad and Tobago', code: 'TT', code2: 'TTO', region: 'Americas',
}, {
  name: 'Tunisia', code: 'TN', code2: 'TUN', region: 'Africa',
}, {
  name: 'Turkey', code: 'TR', code2: 'TUR', region: 'Asia',
}, {
  name: 'Turkmenistan', code: 'TM', code2: 'TKM', region: 'Asia',
}, {
  name: 'Turks and Caicos Islands', code: 'TC', code2: 'TCA', region: 'Americas',
}, {
  name: 'Tuvalu', code: 'TV', code2: 'TUV', region: 'Oceania',
}, {
  name: 'Uganda', code: 'UG', code2: 'UGA', region: 'Africa',
}, {
  name: 'Ukraine', code: 'UA', code2: 'UKR', region: 'Europe',
}, {
  name: 'United Arab Emirates', code: 'AE', code2: 'ARE', region: 'Asia',
}, {
  name: 'United Kingdom of Great Britain and Northern Ireland', code: 'GB', code2: 'GBR', region: 'Europe',
}, {
  name: 'United States of America', code: 'US', code2: 'USA', region: 'Americas',
}, {
  name: 'United States Minor Outlying Islands', code: 'UM', code2: 'UMI', region: 'Oceania',
}, {
  name: 'Uruguay', code: 'UY', code2: 'URY', region: 'Americas',
}, {
  name: 'Uzbekistan', code: 'UZ', code2: 'UZB', region: 'Asia',
}, {
  name: 'Vanuatu', code: 'VU', code2: 'VUT', region: 'Oceania',
}, {
  name: 'Venezuela (Bolivarian Republic of)', code: 'VE', code2: 'VEN', region: 'Americas',
}, {
  name: 'Viet Nam', code: 'VN', code2: 'VNM', region: 'Asia',
}, {
  name: 'Virgin Islands (British)', code: 'VG', code2: 'VGB', region: 'Americas',
}, {
  name: 'Virgin Islands (U.S.)', code: 'VI', code2: 'VIR', region: 'Americas',
}, {
  name: 'Wallis and Futuna', code: 'WF', code2: 'WLF', region: 'Oceania',
}, {
  name: 'Western Sahara', code: 'EH', code2: 'ESH', region: 'Africa',
}, {
  name: 'Yemen', code: 'YE', code2: 'YEM', region: 'Asia',
}, {
  name: 'Zambia', code: 'ZM', code2: 'ZMB', region: 'Africa',
}, {
  name: 'Zimbabwe', code: 'ZW', code2: 'ZWE', region: 'Africa',
}]

export {
  countries,
  states,
  inventories,
  countriesDetail,
  statesByCountry,
  countriesWithRegions,
}
