<template>
  <div class="container-fluid" style="min-height:20vh">
    <div class="row">
      <div class="col-12 p-half">
        <dx-data-grid
          :data-source="users"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-toolbar>
            <dx-item location="before" name="searchPanel" />
            <dx-item location="before" template="buttonCheckBox" />
            <dx-item location="after" template="buttonTemplate" />
          </dx-toolbar>
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-column data-field="fullName" cell-template="fullNameTemplate" />
          <dx-column data-field="login" caption="User Name" />
          <dx-column data-field="email" />
          <dx-column data-field="activated" cell-template="activatedTemplate" width="80" />
          <dx-column data-field="role" />
          <dx-column data-field="departments" :visible="isServingTenant" />
          <dx-column data-field="createdBy" />
          <dx-column data-field="registerTime" caption="Created At" data-type="date" />
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="150" :fixed="true" fixed-position="left" />
          <template #fullNameTemplate="{ data }">
            <div class="d-flex align-items-center">
              <p-avatar
                class="mr-half"
                size="36px"
                color="light-primary"
                round
                :src="data.data.avatar"
                :text="avatarText(data.value)"
              />
              {{ data.value }}
            </div>
          </template>
          <template #activatedTemplate="{data}">
            <div class="badge badge-pill text-capitalize" :class="`badge-light-${resolveUserStatusVariant(data.value)}`">
              {{ data.value }}
            </div>
          </template>
          <template #buttonTemplate>
            <dx-util-button icon="add" text="Add User" type="success" @click="openUserForm" />
          </template>
          <template #buttonCheckBox>
            <dx-util-check-box :value="activated" text="Active Users" :on-value-changed="refreshUserList" class="p-custom" />
          </template>
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                :disabled="isCurrentUser(data.data.id)"
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit User"
                @click="onClickEdit(data)"
              />
              <dx-util-button
                v-if="isWarehouseOwner"
                type="default" icon="bi bi-people"
                class="mr-half" hint="Assign to a department"
                @click="openDepartmentAssignmentPopup(data)"
              />
              <dx-util-button
                :disabled="isCurrentUser(data.data.id)"
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
        </dx-data-grid>
      </div>
      <div class="col-12 px-0">
        <!--Begin:: Create Or Update Form-->
        <dx-util-popup
          ref="userFormPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="User Form"
          @hidden="resetForm"
        >
          <dx-util-position at="center" my="center" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="cancelButtonOptions" />
          <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="saveButtonOptions" />
          <form method="post" @submit="handleSubmit">
            <dx-util-form ref="userFormRef" :form-data="userForm" :col-count="1" :show-colon-after-label="true" label-location="top" validation-group="companyData">
              <dx-util-group-item :col-count="3">
                <dx-util-item data-field="firstName" editor-type="dxTextBox" :label="{text: 'First Name'}" />
                <dx-util-item data-field="middleName" editor-type="dxTextBox" :label="{text: 'Middle Name'}" />
                <dx-util-item data-field="lastName" editor-type="dxTextBox" :label="{text: 'Last Name'}" />
              </dx-util-group-item>
              <dx-util-group-item :col-count="2">
                <dx-util-item data-field="login" editor-type="dxTextBox" :label="{text: 'Username'}">
                  <dx-util-required-rule message="Username is required" />
                </dx-util-item>
                <dx-util-item data-field="email" editor-type="dxTextBox" :label="{text: 'Email'}">
                  <dx-util-required-rule message="Email is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-group-item :col-count="2">
                <dx-util-item data-field="roleId" :editor-options="selectedRoleOptions" editor-type="dxSelectBox" :label="{text: 'Role'}">
                  <dx-util-required-rule message="Role selection is required" />
                </dx-util-item>
                <dx-util-item data-field="warehouseId" :editor-options="selectedWarehouseOptions" editor-type="dxSelectBox" :label="{text: 'Warehouse'}">
                  <dx-util-required-rule message="Warehouse selection is required" />
                </dx-util-item>
              </dx-util-group-item>
              <dx-util-item data-field="activated" :editor-options="selectedStatusOptions" editor-type="dxCheckBox" :label="{text: 'Status'}" />
            </dx-util-form>
          </form>
        </dx-util-popup>
        <!--End:: Create Or Update Form-->
      </div>
      <div class="col-12 px-0">
        <dx-util-popup
          ref="departmentAsignmentPopupRef"
          :show-close-button="true"
          :drag-enabled="false"
          :close-on-outside-click="false"
          :show-title="true"
          :width="600"
          height="auto"
          title="Department Assignment"
          @hidden="resetDepartmentAssignmentForm"
        >
          <div>
            <h5 class="text-warning">
              {{ selectedFullName }}
            </h5>
            <div class="d-flex">
              <dx-util-select-box v-model="selectedDepartment" :data-source="departments" display-expr="departmentName" value-expr="id" class="flex-grow-1 mr-half">
                <dx-util-validator>
                  <dx-util-required-rule message="Department should be selected." />
                </dx-util-validator>
              </dx-util-select-box>
              <dx-util-button text="Assign" type="success" class="ml-half" @click="assignDepartmentToUser" />
            </div>
            <div class="mt-2 border rounded card">
              <h5 class="rounded-top p-1 bg-primary text-white">
                Assigned Departments</h5>
              <div v-if="assignedDepartments && assignedDepartments.length === 0" class="p-1">
                This warehouse user is not assigned to any department.
              </div>
              <div v-for="department in assignedDepartments" :key="department.id" class="d-flex align-items-center mx-1 my-half">
                <span class="flex-grow-1">
                  {{ department.departmentName }}
                </span>
                <span>
                  <dx-util-button icon="icon icon-feather-x" type="danger" @click="removeDepartmentFromUser(department.id)" />
                </span>
              </div>
            </div>
          </div>
        </dx-util-popup>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import { avatarText } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'
import { Notify } from '@robustshell/utils/index'
import userRoleService from '@/http/requests/system/userRoleService'
import userService from '@/http/requests/system/userService'
import shipService from '@/http/requests/ship/shipService'
import departmentService from '@/http/requests/system/departmentService'
import find from 'lodash/find'
import useCurrentUser from '@/libs/app/current-user'
import userManagementService from '@/http/requests/system/userManagementService'
import UserSearchFilter from '@/http/models/search-filters/UserSearchFilter'
import Pager from '@core/dev-extreme/utils/pager'

export default {
  mixins: [GridBase],
  props: {
    companyInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    const userData = getUserData()
    return {
      users: [],
      userForm: {
        id: '',
        firstName: '',
        middleName: '',
        lastName: '',
        login: '',
        email: '',
        userRole: '',
        roleId: '',
        warehouseId: '',
        activated: true,
      },
      warehouses: [],
      currentUser: userData,
      avatarText,
      activated: true,
      currentUserRoles: [],
      departments: [],
      assignedDepartments: [],
      selectedDepartment: null,
      selectedUserId: null,
      selectedFullName: null,
    }
  },
  setup() {
    const {
      isServingTenant,
    } = useCurrentUser()
    return {
      isServingTenant,
    }
  },
  computed: {
    userFormPopup() {
      return this.$refs.userFormPopupRef.instance
    },
    userFormSheet() {
      return this.$refs.userFormRef.instance
    },
    selectedStatusOptions() {
      return {
        text: 'Active User',
      }
    },
    isWarehouseOwner() {
      return this.currentUser.role.roleName === 'WOWNER' || this.currentUser.role.roleName === 'SUPERUSER'
    },
    selectedRoleOptions() {
      return {
        dataSource: this.currentUserRoles,
        displayExpr: 'roleName',
        valueExpr: 'id',
      }
    },
    selectedWarehouseOptions() {
      return {
        dataSource: this.warehouses,
        displayExpr: 'text',
        valueExpr: 'value',
      }
    },
    saveButtonOptions() {
      return {
        text: 'Save',
        type: 'success',
        useSubmitBehavior: true,
        onClick: () => {
          this.handleSubmit()
        },
      }
    },
    cancelButtonOptions() {
      return {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.userFormPopup.hide()
          this.userFormSheet.resetValues()
          this.userForm.selectedStatus = true
        },
      }
    },
  },
  mounted() {
    this.getUsersList()
  },
  methods: {
    getUsersList() {
      UserSearchFilter.setDefaultFilters()
      UserSearchFilter.companyId = this.companyInfo.id
      UserSearchFilter.activated = this.activated
      const filters = UserSearchFilter.getFilters()
      const pager = new Pager()
      userManagementService
        .getUserInfoBasicByQuery(filters, pager.staticPageable)
        .then(response => {
          const data = response.data
          this.users = data.content
        })
    },
    refreshUserList(e) {
      this.activated = e.value
      this.getUsersList()
    },
    openUserForm() {
      this.getWarehouses()
      this.getUserRoles()
      this.userFormPopup.show()
    },
    async getWarehouses() {
      const result = await shipService.fetchWarehouses()
      const data = result.data
      this.warehouses.splice(0, this.warehouses.length)
      // this.warehouses.push({ value: 0, text: 'Select Warehouse' })
      data.forEach(element => {
        if (element.name.toLowerCase() !== 'common') {
          this.warehouses.push({ value: element.id, text: element.name })
        }
      })
      this.userForm.warehouseId = this.warehouses[0].value
      if (this.currentUser.warehouse && this.currentUser.warehouse.id) {
        this.userForm.warehouseId = find(this.warehouses,
          {
            value: this.currentUser.warehouse.id,
          }).value
      }
    },
    getDepartments() {
      departmentService.getTenantDepartments().then(data => {
        this.departments = data
      })
    },
    getAssignedDepartments(userId) {
      departmentService.getDepartmentsByUserId(userId).then(assignedDepartments => {
        this.assignedDepartments = assignedDepartments
      })
    },
    async openDepartmentAssignmentPopup(e) {
      this.selectedUserId = e.data.id
      this.selectedFullName = e.data.fullName
      this.$refs.departmentAsignmentPopupRef.instance.show()
      await this.getDepartments()
      await this.getAssignedDepartments(this.selectedUserId)
    },
    assignDepartmentToUser(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        departmentService.associateDepartmentWarehouseUser(this.selectedDepartment, this.selectedUserId, true).then(() => {
          this.getAssignedDepartments(this.selectedUserId)
        })
      }
    },
    removeDepartmentFromUser(departmentId) {
      departmentService.associateDepartmentWarehouseUser(departmentId, this.selectedUserId, false).then(() => {
        this.getAssignedDepartments(this.selectedUserId)
      })
    },
    async getUserRoles() {
      const result = await userRoleService.getUserSubRoles(this.companyInfo.id)
      const roles = result.data.filter(user => user.roleName !== 'CompanyOwnerRole')
      this.currentUserRoles = []
      if (roles.length === 0) {
        Notify.warning(
          'No Assignable role found. Please first create a role at the Role Management > Roles menu and assign Permissions at the Role Management > Role Permissions menu',
        )
      } else {
        this.currentUserRoles.push(...roles)
      }
    },
    async getUserData(companyId, userId) {
      const result = await userManagementService.fetchCompanyUser(companyId, userId)
      this.currentEditUser = result
    },
    onClickDelete(e) {
      const login = e.data.login
      this.$swal({
        title: `Are you sure you want to delete this user: <span style="color: orange;">${login}</span>?`,
        text: 'This will make you lose your all data about this user.',
        icon: 'warning',
        input: 'text',
        inputPlaceholder: 'Type DELETE to confirm',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
          input: 'my-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          if (result.value === 'DELETE') {
            try {
              await userService.deleteUserByUsername(login)
              this.$swal({
                icon: 'success',
                title: 'Delete',
                text: `${login} is successfully deleted`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getUsersList()
            } catch (err) {
              const messeage = err.message || err
              Notify.error(messeage)
            }
          } else {
            Notify.warning('The user is not deleted. Please type "DELETE" to confirm deletion')
          }
        }
      })
    },
    async onClickEdit(e) {
      this.getWarehouses()
      this.getUserRoles()
      const userId = e.data.id
      let companyId = this.currentUser.companyId
      const pathCompanyId = this.$route.params.companyId
      if (pathCompanyId > 0) {
        companyId = pathCompanyId
      }
      // const companyType = this.currentUser.companyType
      const editUser = await userManagementService.fetchCompanyUser(companyId, userId)
      this.userForm.id = editUser?.id
      this.userForm.firstName = editUser?.firstName
      this.userForm.middleName = editUser?.middleName
      this.userForm.lastName = editUser?.lastName
      this.userForm.login = e.data?.login
      this.userForm.email = e.data?.email
      this.userForm.warehouseId = editUser?.warehouseId
      this.userForm.roleId = editUser?.roleId
      this.userForm.activated = e.data?.activated
      this.userFormPopup.show()
    },
    async handleSubmit() {
      const validationResult = this.userFormSheet.validate()
      // Form manipulation for API validation.
      this.userForm.userRole = {
        user: { id: this.userForm.id || this.currentUser.id },
        role: { id: this.userForm.roleId },
      }
      this.userForm.warehouse = {
        id: this.userForm.warehouseId,
      }
      if (validationResult.isValid) {
        if (this.userForm.id) {
          await userService.updateUser(this.userForm)
        } else {
          await userService.createNewUser(this.userForm)
        }
        this.getUsersList()
        this.userFormPopup.hide()
      }
    },
    resetForm() {
      this.userFormSheet.resetValues()
      this.userForm.id = ''
      this.userForm.activated = true
    },
    isCurrentUser(id) {
      if (!this.currentUser) {
        return false
      }
      return this.currentUser.id === id
    },
    resolveUserStatusVariant(status) {
      if (status) return 'success'
      return 'secondary'
    },
    resetDepartmentAssignmentForm() {
      this.selectedUserId = null
      this.selectedFullName = null
      this.selectedDepartment = null
      this.getUsersList()
    },
  },
}
</script>

<style lang="scss">
.p-custom {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}
</style>
